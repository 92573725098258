<template>
  <v-content>
    <v-row align="center" justify="center">
      <v-col cols="auto">
        <v-icon x-large>mdi-loading mdi-spin</v-icon>
      </v-col>
    </v-row>
  </v-content>
</template>

<script>
import { mapActions, mapState } from "vuex";
import UserProject from "@/repositories/user-project";
import Cognito from "@/repositories/cognito-user";
import Counter from "@/repositories/counter";

export default {
  computed: {
    ...mapState("Cognito", ["client", "userAuthenticated"])
  },
  methods: {
    ...mapActions("Cognito", ["SIGNOUT", "CURRENT_AUTHENTICATED_USER"]),
    logOut() {
      this.SIGNOUT().then(() => {
        this.$router.push({ path: "/" });
      });
    },
    async fetch(id) {
      let res = await Cognito.listGroupsForUser(id);
      let groups = (await res.json()).Groups;
      let admin = false;

      groups.forEach(group => {
        if (
          admin ||
          group.GroupName.toLowerCase() === "admin" ||
          group.GroupName.toLowerCase() === "god"
        )
          admin = true;
      });

      if (admin) {
        this.$router.push({ path: "/admin" });
      } else {
        await Promise.all(
          groups.map(group => {
            return UserProject.get(id, group.GroupName).then(res => res.json());
          })
        ).then(res => {
          try {
            if (res[0][0].access_policy === "admin") {
              this.$router.push({ path: "/admin" });
            } else {
              this.$router.push({
                path: `/project/${res[0][0].project_id}`
              });
            }
          } catch (error) {
            this.logOut();
          }
        });
      }
    }
  },
  watch: {
    userAuthenticated(user) {
      if (user) {
        this.fetch(user.attributes.sub);
        Counter.countUser(this.userAuthenticated.attributes.sub).then(res =>
          res.text()
        );
      }
    }
  },
  created() {
    this.CURRENT_AUTHENTICATED_USER();
  }
};
</script>