<template>
  <v-content v-if="loading">
    <v-row align="center" justify="center">
      <v-col cols="auto">
        <v-icon x-large>mdi-loading mdi-spin</v-icon>
      </v-col>
    </v-row>
  </v-content>
  <v-container
    v-else
    id="container"
    fluid
    :style="{ backgroundColor: colors.loginScreenBackground }"
  >
    <v-row id="row" :class="{ expand : $vuetify.breakpoint.mdAndUp }">
      <v-col
        :class="{ expand : $vuetify.breakpoint.mdAndUp }"
        :cols="$vuetify.breakpoint.mdAndUp ? 7 : 12"
      >
        <Background />
      </v-col>
      <v-col
        :class="{ 'px-6': $vuetify.breakpoint.mdAndDown }"
        :cols="$vuetify.breakpoint.mdAndUp ? 5 : 12"
      >
        <LoginForm />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Background from "@/components/auth/Background.vue";
import LoginForm from "@/components/auth/LoginForm.vue";
import Projects from "@/repositories/projects.js";

export default {
  components: {
    Background,
    LoginForm
  },
  data() {
    return {
      domain: this.$route.params.domain,
      loading: false
    };
  },
  computed: {
    colors() {
      return this.$store.state.colors;
    }
  },
  methods: {
    fetch() {
      this.loading = true;
      Projects.getByDomain(this.domain)
        .then(res => res.text())
        .then(res => {
          const colors = JSON.parse(res).colors;
          if (colors) {
            this.$store.commit("setColor", colors);
          } else {
            this.$router.push({ path: "/login" });
          }
          this.loading = false;
        });
    }
  },
  beforeMount() {
    this.fetch();
  }
};
</script>

<style>
#container {
  background-color: black;
  height: 100%;
}

.expand {
  height: 100%;
  width: 100%;
}
</style>