import Vue from "vue";
import Vuex from "vuex";
import Cognito from "./cognito";
import s3 from "@/repositories/bucket";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    tRequestBearer: null,
    trustedUrl: null,
    folder: null,
    storedItems: [],
    view: null,
    dashboardId: "",
    domain: "",
    dashboardPanel: null,
    model: null,
    urlTableau: null,
    validTokenUrl: "https://trequestv2.qwst.co/t_request2/tokenVaLid",
    tRequestURl: "https://trequestv2.qwst.co/t_request2/getToken",
    colors: {
      loginScreenBackground: "#000000",
      loginScreenFormBackground: "#FFFFFF",
      loginScreenFormInputColors: "#1976D2FF",
      loginScreenFormPrimaryButtons: "#1976d2",
      loginScreenFormPrimaryButtonsText: "#FFFFFF",
      loginScreenFormSecondaryButtons: "#FFFFFF",
      loginScreenFormAlertBackground: "#ff5252",
      loginScreenFormSecondaryButtonsText: "#373737",
      loginScreenFormTitleText: "#373737",
      loginScreenFormForgotPasswordText: "#1976d2",
      loginScreenFormSubtitles: "#1976d2",
      loginScreenBackgroundImg: "common/logo.png",
      startScreen: "#424242",
      startScreenLogo: "common/logo.png",
      startScreenLogoAlign: "left",
      startCards: "#424242",
      startCardsHover: "#FFFFFF",
      startText: "#FFFFFF",
      startTextCard: "#FFFFFF",
      startTextCardHover: "#424242",
      appBar: "#000000",
      icons: "#FFFFFF",
      menu: "#000000",
      isMenuLogoAllowed: true,
      menuLogo: "common/logo_qwst_dash.png",
      text: "#FFFFFF",
    },
    files: [],
  },
  getters: {
    logo(state) {
      try {
        return new URL(state.colors.loginScreenBackgroundImg).href;
      } catch (error) {
        const split = state.colors.loginScreenBackgroundImg.split("/");
        const name = split.pop();
        const path = split.join("/");
        return s3.getSignedUrl(path, name);
      }
    },
    anchorLogo(state) {
      try {
        return new URL(state.colors.startScreenLogo).href;
      } catch (error) {
        const split = state.colors.startScreenLogo.split("/");
        const name = split.pop();
        const path = split.join("/");
        return s3.getSignedUrl(path, name);
      }
    },
    menuLogo(state) {
      try {
        return new URL(state.colors.menuLogo).href;
      } catch (error) {
        const split = state.colors.menuLogo.split("/");
        const name = split.pop();
        const path = split.join("/");
        return s3.getSignedUrl(path, name);
      }
    },
  },
  mutations: {
    setDomain(state, payload) {
      state.domain = payload;
    },
    setTrequestToken(state) {
      state.tRequestBearer = localStorage.getItem("tRequestToken");
    },
    setDashboardId(state, payload) {
      state.dashboardId = payload;
    },
    getTableau(state, payload) {
      (state.folder = payload.folder), (state.view = payload.view);
    },
    setLogo(state, payload) {
      state.colors.loginScreenBackgroundImg = payload;
    },
    setAnchorPageLogo(state, payload) {
      console.log("anchor logo", payload);
      state.colors.startScreenLogo = payload;
    },
    setMenuLogo(state, payload) {
      console.log("menu logo", payload);
      state.colors.menuLogo = payload;
    },
    setFiles(state, payload) {
      state.files = payload;
    },
    getUrl(state, trustUrl) {
      state.trustedUrl = trustUrl;
    },
    fillStoredItems(state, items) {
      state.storedItems = items;
    },
    generateUrl(state, payload) {
      state.folder = payload.folder;
      state.view = payload.view;
    },
    setDashboardPanel(state, payload) {
      state.dashboardPanel = payload;
    },
    setModel(state, payload) {
      state.model = payload;
    },
    setColor(state, payload) {
      state.colors = {
        ...state.colors,
        ...payload,
      };
    },
    defaultColors(state) {
      state.colors = {
        loginScreenBackground: "#000000",
        loginScreenFormBackground: "#FFFFFF",
        loginScreenFormInputColors: "#1976D2FF",
        loginScreenFormPrimaryButtons: "#1976d2",
        loginScreenFormPrimaryButtonsText: "#FFFFFF",
        loginScreenFormSecondaryButtons: "#FFFFFF",
        loginScreenFormAlertBackground: "#ff5252",
        loginScreenFormSecondaryButtonsText: "#373737",
        loginScreenFormTitleText: "#373737",
        loginScreenFormForgotPasswordText: "#1976d2",
        loginScreenFormSubtitles: "#1976d2",
        loginScreenBackgroundImg: "common/logo.png",
        startScreen: "#424242",
        startScreenLogo: "common/logo.png",
        startScreenLogoAlign: "left",
        startCards: "#424242",
        startCardsHover: "#FFFFFF",
        startText: "#FFFFFF",
        startTextCard: "#FFFFFF",
        startTextCardHover: "#424242",
        appBar: "#000000",
        icons: "#FFFFFF",
        menu: "#000000",
        isMenuLogoAllowed: true,
        menuLogo: "common/logo_qwst_dash.png",
        text: "#FFFFFF",
      };
    },
  },
  actions: {
    async verifyToken({ state }, payload) {
      let myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${state.tRequestBearer}`);
      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
      };

      return fetch(state.validTokenUrl, requestOptions);
    },

    async tableauUrl({ state, commit, dispatch }, payload) {
      await dispatch("verifyToken", payload)
        .then((response) => {
          if (
            response.status === 200 &&
            payload.folder &&
            payload.view &&
            state.tRequestBearer
          ) {
            // Se o token é valido gera nova url normalmente
            let myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${state.tRequestBearer}`);
            let requestOptions = {
              method: "GET",
              headers: myHeaders,
              redirect: "follow",
            };
            fetch(
              `https://trequestv2.qwst.co/t_request2?folder=${payload.folder}&view=${payload.view}`,
              requestOptions
            )
              .then((response) => response.json())
              .then((result) => {
                let pre_url = result;
                if (pre_url.includes("?")) {
                  // console.log("params found");
                  pre_url = `${result}&pad_email=${Cognito.state.userAuthenticated.attributes.email}`;
                } else {
                  // console.log("params not found");
                  pre_url = `${result}?pad_email=${Cognito.state.userAuthenticated.attributes.email}`;
                }
                this.state.trustedUrl = pre_url;
              })
              .catch();
          } else if (response.status === 401) {
            console.clear();
            // Se o token é invalido gera novo token
            let myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            let requestOptions = {
              method: "GET",
              headers: myHeaders,
              mode: "cors",
              redirect: "follow",
            };
            fetch(state.tRequestURl, requestOptions)
              .then((response) => response.json())
              .then((result) => {
                localStorage.setItem("tRequestToken", result.Bearer);
                commit("setTrequestToken", {
                  setTrequestToken: localStorage.getItem("tRequestToken"),
                });
                let myHeaders = new Headers();
                myHeaders.append(
                  "Authorization",
                  `Bearer ${state.tRequestBearer}`
                );
                let requestOptions = {
                  method: "GET",
                  headers: myHeaders,
                  redirect: "follow",
                };
                // Gera tableauUrl após o novo token ser criado
                fetch(
                  `https://trequestv2.qwst.co/t_request2?folder=${payload.folder}&view=${payload.view}`,
                  requestOptions
                )
                  .then((response) => response.json())
                  .then((result) => {
                    let pre_url = result;
                    if (pre_url.includes("?")) {
                      // console.log("params found");
                      pre_url = `${result}&pad_email=${Cognito.state.userAuthenticated.attributes.email}`;
                    } else {
                      // console.log("params not found");
                      pre_url = `${result}?pad_email=${Cognito.state.userAuthenticated.attributes.email}`;
                    }
                    this.state.trustedUrl = pre_url;
                  })
                  .catch();
              })
              .catch();
          }
        })
        .catch();
    },
  },
  modules: {
    Cognito,
  },
});
