<template>
  <div>
    <v-data-table :headers="headers" :items="items" :loading="loading" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-btn color="green" outlined class="mb-2" :to="{ path: 'create' }" append>Novo projeto</v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.active="{ item }">{{ item.active | active }}</template>
      <template v-slot:item.id="{ item }">{{ item.id | simplifyUuid }}</template>
      <template v-slot:item.created_at="{ item }">{{ item.created_at | localeDate }}</template>
      <template v-slot:item.updated_at="{ item }">{{ item.updated_at | localeDate }}</template>
      <template v-slot:item.url="{ item }">
        <a :href="item.domain | path" target="_blank">{{ item.domain | path }}</a>
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn outlined class="mr-2" small :to="{ path: `/project/${item.id}` }">
          <v-icon small>mdi-monitor-dashboard</v-icon>
        </v-btn>
        <v-btn
          outlined
          class="mr-2"
          color="blue"
          dark
          small
          :to="{ path: `${item.id}/update` }"
          append
        >
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
        <v-btn outlined class="mr-2" color="red" dark small @click="deleteScreen(item)">
          <v-icon small>mdi-delete</v-icon>
        </v-btn>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="fetch">Reset</v-btn>
      </template>
    </v-data-table>
    <v-dialog v-model="deleteDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Deletar projeto</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <p>Você tem certeza de que deseja deletar este projeto?</p>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="deleteDialogSave(item)">Sim</v-btn>
          <v-btn color="blue darken-1" text @click="deleteDialogClose">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Projects from "@/repositories/projects";
import UserProjects from "@/repositories/user-project";
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      toBeDeleted: null,
      deleteDialog: false,
      items: [],
      headers: [
        // { text: "Status", value: "active" },
        // { text: "Id", value: "id" },
        { text: "Nome", value: "name" },
        { text: "Domínio", value: "domain" },
        { text: "Id", value: "id" },
        { text: "URL", value: "url" },
        { text: "Criação", value: "created_at" },
        { text: "Última atualização", value: "updated_at" },
        { text: "", value: "action", sortable: false }
      ],
      loading: false
    };
  },
  computed: {
    ...mapState("Cognito", ["client", "userAuthenticated"]),
    ...mapState(["urlTableau"]),
   
  },
  methods: {
    ...mapActions("Cognito", ["CURRENT_AUTHENTICATED_USER"]),
    ...mapActions(["tableauUrl"]),
    fetch() {
      this.loading = true;
      Projects.getAll()
        .then(res => res.text())
        .then(res => {
          this.items = JSON.parse(res);
          this.loading = false;
        });
    },
    deleteItem(item) {
      this.items.splice(this.items.indexOf(item), 1);
      Projects.delete(item)
        .then(res => res.text())
        .then(res => console.log(res));
      UserProjects.deleteProject(item)
        .then(res => res.text())
        .then(res => console.log(res));
    },
    deleteScreen(item) {
      this.toBeDeleted = item;
      this.deleteDialog = true;
    },
    deleteDialogSave() {
      this.deleteItem(this.toBeDeleted);
      this.deleteDialogClose();
    },
    deleteDialogClose() {
      this.deleteDialog = false;
    }
  },
  mounted() {
    this.fetch();
  },
  watch: {
    token: {
      handler(value) {
        if (value) this.fetch();
      },
      immediate: true
    },
  },
  filters: {
    active: status => (status ? "Ativo" : "Inativo"),
    localeDate: date => (date ? new Date(date).toLocaleString() : ""),
    simplifyUuid: uuid => uuid.split("-")[0],
    activateBtn: status => (status ? "Desativar" : "Reativar"),
    path: domain => `${window.location.origin}/#/${domain}/login`
  },
  created() {
    this.CURRENT_AUTHENTICATED_USER();
  }
};
</script>