<template>
  <v-app id="sandbox">
    <v-dialog width="500" v-model="resetPwdDialog">
      <v-window class="bgDialog" v-model="resetStep">
        <v-window-item :value="'new-pwd-confirm'">
          <v-form v-model="valid">
            <v-card-text>
              <v-text-field
                required
                label="Senha"
                :rules="[cantEmptyRule, passwordValidRule]"
                type="password"
                v-model="pwd"
              ></v-text-field>
              <v-text-field
                required
                label="Confirme a senha"
                v-model="pwdConfirm"
                :rules="[
                  cantEmptyRule,
                  passwordConfirmationRule,
                  passwordValidRule,
                ]"
                type="password"
              ></v-text-field>
              <span class="caption"
                >Por favor, insira uma senha para sua conta</span
              >
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn @click="closeDialog" text>Cancelar</v-btn>
              <v-spacer></v-spacer>
              <v-btn :disabled="!valid" depressed @click="sendCode"
                >Avançar</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-window-item>
        <v-window-item :value="'code'">
          <v-form v-model="validCode">
            <v-card-text>
              <v-text-field
                :rules="[
                  (e) => !!e || 'Digite o codigo enviado para o email',
                  (e) => e.length === 6 || 'O codigo contém 6 digitos',
                ]"
                v-model="codeValue"
              ></v-text-field>
              <span class="caption grey--text text--darken-1"
                >Insira o código de confirmação enviado para o seu e-mail</span
              >
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn :disabled="!validCode" @click="changePwd()" depressed
                >Confirmar</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-window-item>
      </v-window>
    </v-dialog>
    <v-navigation-drawer
      v-model="drawer.model"
      :clipped="drawer.clipped"
      :floating="drawer.floating"
      :mini-variant="drawer.mini"
      :permanent="drawer.type === 'permanent'"
      :temporary="drawer.type === 'temporary'"
      app
      overflow
    >
      <v-list dense nav>
        <v-list-item-group v-model="item">
          <v-list-item v-for="(item, i) in items" :key="i" :to="item.to" append>
            <v-list-item-content>
              <v-list-item-title
                ><v-icon left>{{ item.icon }}</v-icon
                >{{ item.name }}</v-list-item-title
              >

              <!-- <v-list-item-icon> -->
              <!-- Adicione o ícone desejado abaixo, por exemplo: -->
              <!-- <v-icon>{{ getIcon(item.name) }}</v-icon> -->
              <!-- </v-list-item-icon> -->
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar dense :clipped-left="drawer.clipped" app>
      <v-app-bar-nav-icon
        v-if="drawer.type !== 'permanent'"
        @click.stop="drawer.model = !drawer.model"
      />
      <v-toolbar-title>{{ pageName }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <span v-if="userAuthenticated">{{
        userAuthenticated.attributes.email
      }}</span>

      <v-menu left bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="openDialog">
            <v-list-item-title>Alterar senha</v-list-item-title>
          </v-list-item>
          <v-list-item @click="logOut">
            <v-list-item-title>Sair</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-content id="content_panel">
      <v-container fluid>
        <v-row align="center" justify="center">
          <router-view />
        </v-row>
      </v-container>
    </v-content>
    <v-snackbar
      v-model="snackbar.active"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}
      <v-btn text outlined @click="snackbar.active = false">Fechar</v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import Amplify from "aws-amplify";

const client = process.env.VUE_APP_CLI;

Amplify.configure({
  Auth: {
    userPoolId: process.env.VUE_APP_UPD,
    region: "us-east-1",
    userPoolWebClientId: client,
  },
});

export default {
  data: () => ({
    create: false,
    item: 1,
    valid: true,
    validCode: true,
    codeValue: "",
    snackbar: {
      active: false,
      text: "",
      color: "",
      timeout: 4000,
    },
    pwd: "",
    pwdConfirm: "",
    resetStep: "new-pwd-confirm",
    resetPwdDialog: false,
    drawer: {
      model: null,
      type: "default (no property)",
      clipped: false,
      floating: false,
      mini: false,
    },
    items: [
      {
        name: "Início",
        icon: "mdi-home",
        to: {
          path: "/home",
          params: {},
        },
      },
      {
        name: "Projetos",
        icon: "mdi-view-dashboard",
        to: {
          path: "/admin/projects",
          params: {},
        },
      },
      {
        name: "Usuários",
        icon: "mdi-account-group",
        to: {
          path: "/admin/users",
          params: {},
        },
      },
    ],
  }),
  // watch: {
  //   item(newValue, oldValue) {
  //     if (newValue === undefined) this.item = oldValue;
  //   }
  // },
  computed: {
    ...mapState("Cognito", ["client", "userAuthenticated"]),
    pageName() {
      return this.$route.meta.title;
    },
    passwordConfirmationRule() {
      return () => this.pwd === this.pwdConfirm || "As senhas não estão iguais";
    },

    cantEmptyRule() {
      return (v) => !!v || "Campo não pode ficar vazio!";
    },
    passwordValidRule() {
      return () =>
        /^.*(?=.{6,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/.test(
          this.pwd
        ) ||
        "A senha contém pelo menos um caractere especial, um número, uma letra maiúscula e uma minúscula";
    },
  },
  methods: {
    ...mapActions("Cognito", ["SIGNOUT", "CURRENT_AUTHENTICATED_USER"]),
    logOut() {
      this.SIGNOUT().then(() => {
        this.$router.push({ path: "/" });
      });
    },
    activateSnackbar(text, color, timeout) {
      this.snackbar = { ...this.message, active: true, text, color, timeout };
    },
    openDialog() {
      this.resetStep = "new-pwd-confirm";
      this.resetPwdDialog = true;
    },
    closeDialog() {
      this.resetPwdDialog = false;
    },
    sendCode() {
      Amplify.Auth.forgotPassword(this.userAuthenticated.attributes.email)
        .then((data) => console.log(data))
        .catch((err) => console.log(err));
      this.resetStep = "code";
    },
    changePwd() {
      Amplify.Auth.forgotPasswordSubmit(
        this.userAuthenticated.attributes.email,
        this.codeValue,
        this.pwdConfirm
      )
        .then((data) => {
          console.log(data);
          this.resetPwdDialog = false;
          this.resetStep = "new-pwd-confirm";
          this.activateSnackbar("Senha alterada com sucesso!", "success");
        })
        .catch((err) => {
          console.log(err);
          if (
            err.message ===
            "Invalid verification code provided, please try again."
          ) {
            this.activateSnackbar(
              "Código inválido, por favor tente novamente.",
              "error"
            );
          } else if (
            err.message ===
            "Attempt limit exceeded, please try after some time."
          ) {
            this.activateSnackbar(
              "Limite de tentativas excedido, aguarde para tentar novamente.",
              "error"
            );
          } else {
            this.activateSnackbar(err.message, "error");
          }
        });
    },
  },
  created() {
    this.CURRENT_AUTHENTICATED_USER();
  },
};
</script>

<style lang="scss" scoped>
#sandbox {
  width: 100% !important;
}

#content_panel {
  padding: 0 !important;
}

.bgDialog {
  background-color: #ffffff;
}
</style>