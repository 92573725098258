import Auth from "./_auth"
// Load the SDK para JavaScript
const AWS = require('aws-sdk');
// Set the Region 
AWS.config.update({ region: 'us-east-1' });


const identityPoolId = process.env.VUE_APP_IPI
const UserPoolId = process.env.VUE_APP_UPD

// Todas as Funções foram alteradas para async...
export default {

    async listObjects(path) {

        const userPoolId = UserPoolId
        const token = (await Auth.token()).getIdToken().jwtToken;
        const s3 = new AWS.S3({
            credentials: new AWS.CognitoIdentityCredentials({
            IdentityPoolId: identityPoolId,
            Logins: {
              [`cognito-idp.${AWS.config.region}.amazonaws.com/${userPoolId}`]: token,
            },
          }),
        });

        //console.log("Passei por listObjects...")
        let params = {
            Bucket: "pad-qwst-s3-bucket",
            Prefix: path,
        };

        return s3.listObjects(params).promise();
    },
    async putObject(path, file) {

        const userPoolId = UserPoolId
        const token = (await Auth.token()).getIdToken().jwtToken;
        const s3 = new AWS.S3({
            credentials: new AWS.CognitoIdentityCredentials({
            IdentityPoolId: identityPoolId,
            Logins: {
              [`cognito-idp.${AWS.config.region}.amazonaws.com/${userPoolId}`]: token,
            },
          }),
        });

        let params = {
            Bucket: "pad-qwst-s3-bucket",
            ContentType: file.type,
            Body: file,
            Key: `${path}/${file.name}`,
        };

        return s3.putObject(params).promise();
    },
    async getObject(path, name) {

        const userPoolId = UserPoolId
        const token = (await Auth.token()).getIdToken().jwtToken;
        const s3 = new AWS.S3({
            credentials: new AWS.CognitoIdentityCredentials({
            IdentityPoolId: identityPoolId,
            Logins: {
              [`cognito-idp.${AWS.config.region}.amazonaws.com/${userPoolId}`]: token,
            },
          }),
        });

        //console.log("Passei por getObject...")
        let params = {
            Bucket: "pad-qwst-s3-bucket",
            Key: `${path}/${name}`,
        };

        return s3.getObject(params).promise();
    },
     async getSignedUrl(path, name) {
        
        const userPoolId = UserPoolId;
        const token = (await Auth.token()).getIdToken().jwtToken;
        const s3 = new AWS.S3({
            signatureVersion: 'v4',
            credentials: new AWS.CognitoIdentityCredentials({
            IdentityPoolId: identityPoolId,
            Logins: {
              [`cognito-idp.${AWS.config.region}.amazonaws.com/${userPoolId}`]: token,
            },
          }),
        });

        //console.log("Passei por getSignedUrl...")

        // Aqui começa...
        let params = {
            Bucket: "pad-qwst-s3-bucket",
            Key: path + (name ? `/${name}` : ""),
            Expires: 60
        };

        const url = await s3.getSignedUrlPromise("getObject", params);

        // console.log("Params --> ",params);
        // console.log("Url --> ",url);

        return url;

    },

    async deleteObjects(path, names) {

        const userPoolId = UserPoolId
        const token = (await Auth.token()).getIdToken().jwtToken;
        const s3 = new AWS.S3({
            credentials: new AWS.CognitoIdentityCredentials({
            IdentityPoolId: identityPoolId,
            Logins: {
              [`cognito-idp.${AWS.config.region}.amazonaws.com/${userPoolId}`]: token,
            },
          }),
        });

        let files = []

        for (let name of names) {
            const fullPath = `${path}/${name}`;
            files.push({ Key: fullPath });
        }
        let params = {
            Bucket: "pad-qwst-s3-bucket",
            Delete: {
                Objects: files
            }
        };
        return s3.deleteObjects(params).promise();
    },
    async moveObject(source, destination) {

        const userPoolId = UserPoolId
        const token = (await Auth.token()).getIdToken().jwtToken;
        const s3 = new AWS.S3({
            credentials: new AWS.CognitoIdentityCredentials({
            IdentityPoolId: UserPoolId,
            Logins: {
              [`cognito-idp.${AWS.config.region}.amazonaws.com/${userPoolId}`]: token,
            },
          }),
        });

        const bucket = "pad-qwst-s3-bucket"
        let params = {
            Bucket: bucket,
            CopySource: [bucket, source].join("/"),
            Key: destination,
        };
        return s3.copyObject(params).promise()
    }
}


//------------------------------Código anterior -------------------------------------------------


// export default {
//     listObjects(path) {
//         let params = {
//             Bucket: process.env.VUE_APP_S3_OBJ,
//             Prefix: path,
//         };

//         return s3.listObjects(params).promise();
//     },
    
//     async putObject(path, file) {
//         let params = {
//             Bucket: process.env.VUE_APP_S3_OBJ,
//             ContentType: file.type,
//             Body: file,
//             Key: `${path}/${file.name}`,
//         };

//         return s3.putObject(params).promise();
//     },
//     getObject(path, name) {
//         let params = {
//             Bucket: process.env.VUE_APP_S3_OBJ,
//             Key: `${path}/${name}`,
//         };

//         return s3.getObject(params).promise();
//     },
//     getSignedUrl(path, name) {
//         let params = {
//             Bucket: process.env.VUE_APP_S3_OBJ,
//             Key: path + (name ? `/${name}` : ""),
//             Expires: 60
//         };
//         const url = s3.getSignedUrl("getObject", params);

//         return url;
//     },
//     deleteObjects(path, names) {
//         let files = []

//         for (let name of names) {
//             const fullPath = `${path}/${name}`;
//             files.push({ Key: fullPath });
//         }
//         let params = {
//             Bucket: process.env.VUE_APP_S3_OBJ,
//             Delete: {
//                 Objects: files
//             }
//         };
//         return s3.deleteObjects(params).promise();
//     },
//     async moveObject(source, destination) {
//         const bucket = process.env.VUE_APP_S3_OBJ
//         let params = {
//             Bucket: bucket,
//             CopySource: [bucket, source].join("/"),
//             Key: destination,
//         };
//         return s3.copyObject(params).promise()
//     }
// }