import Amplify from "aws-amplify";

const client = process.env.VUE_APP_CLI;

Amplify.configure({
  Auth: {
    userPoolId: process.env.VUE_APP_UPD,
    region: "us-east-1",
    userPoolWebClientId: client,
  },
});
export const AWS = Amplify;

export default {
  namespaced: true,
  state: {
    client,
    formValid: false,
    loader: null,
    userAuthenticated: null,
    userGroups: null,
    messageError: "",
    tRequestToken: null,
    tRequestURl: "https://trequestv2.qwst.co/t_request2/getToken",
    removeTrequestToken: "https://trequestv2.qwst.co/t_request2/deleteToken",
    typeError: {
      "Incorrect username or password.": "Usúario ou senha incorreto!",
      "An account with the given email already exists.":
        "Existe um usúario com esse email",
      "Invalid verification code provided, please try again.":
        "Código de verificação está incorreto",
      "not authenticated": "Você deve entrar antes para acessar.",
      "User is disabled.": "Usuário desabilitado",
      "Password reset required for the user": "Por favor, altere sua senha.",
      "Cannot read property 'signInUserSession' of null":"Você saiu da aplicação.",
      "Cannot read properties of null (reading 'signInUserSession')":"Você saiu da aplicação.",
    },
    alert: false,
    form: {
      email: "",
      password: "",
      passwordConfirm: "",
      confirmationCode: "",
    },
    step: "login",
    steps: {
      login: {
        title: "Entrar",
        subtitle: "",
      },
      "forgot-password": {
        title: "Alterar senha",
        subtitle: "",
      },
      "register-email": {
        title: "Inserir E-mail",
        subtitle: "Insira seu e-mail",
      },
      "register-password": {
        title: "Inserir Senha",
        subtitle: "Insira sua senha",
      },
      "confirmation-code": {
        title: "Criar nova senha",
        subtitle:
          "Digite sua nova senha e o código de confirmação enviado para o seu e-mail",
      },
      "new-account-password": {
        title: "Alterar senha temporária",
        subtitle: "",
      },
    },
    forgotSteps: false,
  },
  mutations: {
    CHANGE_STEP(state, payload) {
      state.step = payload;
      if (payload === "forgot-password") state.forgotSteps = true;
      if (payload === "new-account-password") state.forgotSteps = true;
      if (payload === "login") state.forgotSteps = null;
    },

    CHANGE_FORM(state, payload) {
      state.form[payload.input] = payload.value;
    },

    CHANGE_ERROR(state, payload) {
      state.messageError = state.typeError[payload]
        ? state.typeError[payload]
        : payload;
      state.alert = true;
      setTimeout(() => {
        state.alert = false;
        state.messageError = "";
      }, 3000);
    },

    CHANGE_LOADER(state, payload) {
      state.loader = payload;
    },

    CHANGE_USER_AUTHENTICATED(state, payload) {
      state.userAuthenticated = payload;
      state.userGroups =
        payload.signInUserSession.accessToken.payload["cognito:groups"];
    },

    CHANGE_FORM_VALID(state, payload) {
      state.formValid = payload;
    },

    CLEAR_PASSWORD(state) {
      state.form.password = "";
      state.form.passwordConfirm = "";
    },

    T_REQUEST_TOKEN(state, payload) {
      state.tRequestToken = payload;
    },
  },
  actions: {
    async CURRENT_AUTHENTICATED_USER({ commit }) {
      try {
        const data = await Amplify.Auth.currentAuthenticatedUser();
        commit("CHANGE_USER_AUTHENTICATED", data);
      } catch (err) {
        commit("CHANGE_ERROR", err.message || err);
      }
    },

    async REGISTER_USER({ commit, state }) {
      commit("CHANGE_LOADER", true);
      try {
        await Amplify.Auth.signUp({
          username: state.form.email,
          password: state.form.password,
          attributes: {
            email: state.form.email,
          },
        });
        commit("CHANGE_STEP", "confirmation-code");
      } catch (err) {
        commit("CHANGE_ERROR", err.message);
      } finally {
        commit("CHANGE_LOADER", false);
      }
    },

    async FORGOT_PASSWORD_AUTHORIZATION({ commit, state }) {
      commit("CHANGE_LOADER", true);
      try {
        await Amplify.Auth.forgotPassword(state.form.email);
        commit("CHANGE_STEP", "confirmation-code");
      } catch (err) {
        commit("CHANGE_ERROR", err.message);
      } finally {
        commit("CHANGE_LOADER", false);
      }
    },

    async FORGOT_PASSWORD_SUBMIT({ commit, state }) {
      commit("CHANGE_LOADER", true);
      try {
        await Amplify.Auth.forgotPasswordSubmit(
          state.form.email,
          state.form.confirmationCode,
          state.form.password
        );
        commit("CLEAR_PASSWORD");
        commit("CHANGE_STEP", "login");
      } catch (err) {
        commit("CHANGE_ERROR", err.message);
      } finally {
        commit("CHANGE_LOADER", false);
      }
    },

    async SIGNIN({ commit, state, dispatch }) {
      commit("CHANGE_LOADER", true);
      try {
        const data = await Amplify.Auth.signIn(
          state.form.email,
          state.form.password
        );
        commit("CHANGE_USER_AUTHENTICATED", data);
        dispatch("GET_TOKEN");
      } catch (err) {
        commit("CHANGE_ERROR", err.message);
      } finally {
        commit("CHANGE_LOADER", false);
      }
    },

    async NEW_PASSWORD_REQUIRED({ commit, state }) {
      commit("CHANGE_LOADER", true);
      // console.log('NEW_PASSWORD_REQUIRED')
      try {
        Amplify.Auth.completeNewPassword(
          state.userAuthenticated,
          state.form.password
          // state.userAuthenticated.challengeParam.userAttributes
        );

        commit("CHANGE_STEP", "new-account-password");
        commit("CHANGE_FORM", { input: "email", value: "" });
        commit("CHANGE_FORM", { input: "password", value: "" });
        commit("CHANGE_FORM", { input: "confirmationCode", value: "" });
        commit("CHANGE_STEP", "login");
      } catch (err) {
        commit("CHANGE_ERROR", err.message);
      } finally {
        commit("CHANGE_LOADER", false);
      }
    },

    async GET_TOKEN({ commit, state }) {
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        mode: "cors",
        redirect: "follow",
      };

      fetch(state.tRequestURl, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          localStorage.setItem("tRequestToken", result.Bearer);
          commit(
            "setTrequestToken",
            {
              setTrequestToken: localStorage.getItem("tRequestToken"),
            },
            { root: true }
          );
        })
        .catch((error) => console.log("error", error));
    },

    async SIGNOUT({ commit, dispatch }) {
      commit("CHANGE_LOADER", true);
      try {
        await Amplify.Auth.signOut();
        dispatch("REMOVE_TOKEN");
        commit("CHANGE_USER_AUTHENTICATED", null);
      } catch (err) {
        commit("CHANGE_ERROR", err.message);
      } finally {
        commit("CHANGE_LOADER", false);
      }
    },

    REMOVE_TOKEN({ state }) {
      var myHeaders = new Headers();
      var token = localStorage.getItem("tRequestToken");

      myHeaders.append("Authorization", `Bearer ${token}`);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(state.removeTrequestToken, requestOptions)
        .then((response) => response.text())
        .then(localStorage.removeItem("tRequestToken"))
        .catch((error) => console.log("error", error));
    },

    async CODE_CONFIRM_REGISTER({ commit, state }) {
      commit("CHANGE_LOADER", true);
      try {
        await Amplify.Auth.confirmSignUp(
          state.form.email,
          state.form.confirmationCode,
          {
            forceAliasCreation: true,
          }
        );
        commit("CHANGE_STEP", "login"), commit("CLEAR_PASSWORD");
      } catch (err) {
        commit("CHANGE_ERROR", err.message);
      } finally {
        commit("CHANGE_LOADER", false);
      }
    },
  },
  getters: {
    currentWindow(state) {
      return state.steps[state.step];
    },

    passwordConfirmationRule(state) {
      return () =>
        state.form.password === state.form.passwordConfirm ||
        "As senhas não estão iguais";
    },

    cantEmptyRule() {
      return (v) => !!v || "Campo não pode ficar vazio!";
    },

    emailValidRule(state) {
      return () => /.+@.+\..+/.test(state.form.email) || "E-mail Inválido";
    },

    passwordValidRule(state) {
      return () =>
        /^.*(?=.{6,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/.test(
          state.form.password
        ) ||
        "A senha contém pelo menos um caractere especial, um número, uma letra maiúscula e uma minúscula";
    },
  },
};
