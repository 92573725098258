<template>
  <div>
    <v-btn
      :loading="loading"
      @click="callDownload()"
      color="primary"
      fixed
      fab
      bottom
      right
    >
      <v-icon>mdi-download</v-icon>
    </v-btn>
    <template v-show="false">
      <a ref="download"></a>
    </template>
  </div>
</template>

<script>
import XLSX from "xlsx";
import UserProjects from "@/repositories/user-project";

export default {
  name: "Download",
  props: ["items", "counter"],
  data: () => ({
    // userList: this.userListRepo,
    realItems: [],
    loading: false,
    columns: {
      Email: "E-mail",
      Username: "ID",
      Count: "Número de acessos",
      Projeto: "Projeto",
      Dashboards: "Dashboards",
      LastAccess: "Data do último acesso",
      UserCreateDate: "Data da criação do usuário",
      UserLastModifiedDate: "Data da última modificação",
    },
    userProjectData: null,
  }),

  computed: {},

  methods: {
    // downloadGenerator(){
    //   const generator = this.callDownload().execute
    //   generator.next()
    // },
    callDownload() {
      this.loading = true;
      this.download();
    },
    formatDates(date) {
      let dateTurn = date.split("T")[0];
      let split = dateTurn.split("-");
      date = split;
      date = new Date(`${split[1]}/${split[2]}/${split[0]}`);
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    },
    download() {
      this.gettingProjects();
    },
    gettingProjects() {
      let itemsRepo = this.items;
      itemsRepo.forEach(
        (item) => {
          item["Count"] = this.counter[item.Username].Count;
          item["LastAccess"] = this.counter[item.Username].LastAccess;

          UserProjects.getByUser(item.Username)
            .then((res) => res.json())
            .then((res) => {
              item["Projeto"] = res[0].project_id;
              item["Dashboards"] = res[0].project_filters.join(",");
            });
        },
        setTimeout(() => {
          this.items = itemsRepo;
          this.intoExcel();
        }, 30000)
      );
    },
    intoExcel() {
      const arrayEntrevistas = this.items.map((entrevista) => {
        let result = {};
        for (let column in this.columns) {
          result = {
            ...result,
            [this.columns[column]]: entrevista[column],
          };
        }
        return result;
      });
      const entrevistas = XLSX.utils.json_to_sheet(arrayEntrevistas);
      const workbook = XLSX.utils.book_new();
      const filename = `Usuários ${new Date().toISOString()}.xlsx`;
      XLSX.utils.book_append_sheet(workbook, entrevistas, "Usuários");

      XLSX.writeFile(workbook, filename);

      this.loading = false;
    },
  },
};
</script>