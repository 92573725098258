<template>
  <v-content v-if="loading">
    <v-row align="center" justify="center">
      <v-col cols="auto">
        <v-icon x-large>mdi-loading mdi-spin</v-icon>
      </v-col>
    </v-row>
  </v-content>
  <v-app v-else id="sandbox">
    <v-content :style="{ background: colors.startScreen }" id="content_panel">
      <v-item-group>
        <v-container>
          <v-row>
            <v-col v-if="startScreenPath !== 'common/logo.png'" cols="12">
              <v-img :src="startScreenLogo" width="10%" :style="this.alignStyles"></v-img>
            </v-col>
          </v-row>
          <h2 :style="{ color: colors.startText }" class="title my-6">
            Acesse um dos dashboards disponíveis
            <!-- <router-link :to="{ path: '/home'}">ou volte à seleção de projetos</router-link> -->
          </h2>
          <v-row align="center" justify="center" v-if="project">
            <v-col
              class="card"
              cols="12"
              md="6"
              lg="3"
              v-for="(level, index) in project.levels"
              :key="index"
            >
              <v-hover v-if="project.levels[index].cardBackground" v-slot:default="{ hover }">
                <v-card
                  @click="selectDashboardPanel(level)"
                  :elevation="hover ? 16 : 2"
                  class="card-img d-flex align-center"
                  style="transition: all 0.35s ease;"
                  :color="hover ? colors.startCardsHover : colors.startCards"
                  :style="{ 'background-image': `url(${level.imgUrl})` }"
                  height="200"
                >
                  <v-scroll-y-transition>
                    <!-- <v-img :src="level.imgUrl" class="card-img white--text align-center"> -->
                    <div
                      :style="{ color: hover ? colors.startTextCardHover : colors.startTextCard }"
                      class="card-text display-3 flex-grow-1 text-center"
                    >{{ level.name }}</div>
                    <!-- </v-img> -->
                  </v-scroll-y-transition>
                </v-card>
              </v-hover>
              <v-hover v-else v-slot:default="{ hover }">
                <v-card
                  @click="selectDashboardPanel(level)"
                  :elevation="hover ? 16 : 2"
                  class="d-flex align-center"
                  style="transition: all 0.35s ease;"
                  :color="hover ? colors.startCardsHover : colors.startCards"
                  height="200"
                >
                  <v-scroll-y-transition>
                    <!-- <v-img :src="level.imgUrl" class="card-img white--text align-center"> -->
                    <div
                      :style="{ color: hover ? colors.startTextCardHover : colors.startTextCard }"
                      class="display-3 flex-grow-1 text-center"
                    >{{ level.name }}</div>
                    <!-- </v-img> -->
                  </v-scroll-y-transition>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-container>
      </v-item-group>
    </v-content>
  </v-app>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Projects from "@/repositories/projects";
import UserProject from "@/repositories/user-project";
import Bucket from "@/repositories/bucket";

export default {
  data() {
    return {
      align: "",
      isMobile: false,
      loading: true,
      id: this.$route.params.id,
      project: null,
      drawer: {
        model: null,
        type: "default (no property)",
        clipped: false,
        floating: false,
        mini: false
      },
      // Iníio
      startScreenLogoUrl: null,
      cardBackgroundImg: null,


    };
  },
  computed: {
    ...mapState("Cognito", ["client", "userAuthenticated"]),
    colors() {
      return this.$store.state.colors;
    },
    startScreenPath() {
      return this.$store.state.colors.startScreenLogo;
    },
    startScreenLogo() {

      // // Chama a função assíncrona e atualiza a propriedade de dados
      this.loadStartScreenLogo();
      return this.startScreenLogoUrl;

      // return this.startScreenPath
      //   ? Bucket.getSignedUrl(this.startScreenPath, "")
      //   : Bucket.getSignedUrl("common/logo.png", "");
    },
    alignStyles() {
      if (this.isMobile === false) {
        return this.returnAlign();
      } else {
        return "display: block; margin:0 auto; width: 40%;";
      }
    }
  },
  watch: {
    project(value) {
      if (value) {
        this.$store.commit("setColor", value.colors);
      }
    },
    token(value) {
      if (value) this.fetch();
    }
  },
  methods: {
    ...mapActions("Cognito", ["SIGNOUT", "CURRENT_AUTHENTICATED_USER"]),
    logOut() {
      this.SIGNOUT().then(() => {
        this.$router.push({ path: "/" });
      });
    },

    //------------Início---------------
    // Obtem o Logo Principal
    async loadStartScreenLogo() {
      if (this.startScreenLogoUrl !== null) {
        return
      }
      try {
        // Simulação de uma chamada assíncrona, por exemplo, uma requisição HTTP
        const signedUrl = await Bucket.getSignedUrl(this.startScreenPath, "");
        this.startScreenLogoUrl = signedUrl;
      } catch (error) {
        console.error('Erro na chamada assíncrona', error);
      }
    },

    // Obtem imagens de fundo dos Cards
    async loadCardBackgroundImg(id,value) {
      try {
        // Simulação de uma chamada assíncrona, por exemplo, uma requisição HTTP
        const signedUrl = await Bucket.getSignedUrl(id,value);
        this.cardBackgroundImg = signedUrl;       
      } catch (error) {
        console.error('Erro na chamada assíncrona', error);
      }
    },

    // Força o carregamento das imagens nos Cards
    async preloadImage(url) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(url);
        img.onerror = reject;
        img.src = url;
      });
    },


    //------------Fim------------------


    returnAlign() {
      if (this.$store.state.colors.startScreenLogoAlign === 0) {
        return "float: left;";
      } else if (this.$store.state.colors.startScreenLogoAlign === 1) {
        return "display: block; margin:0 auto;";
      } else if (this.$store.state.colors.startScreenLogoAlign === 2) {
        return "float: right;";
      }
    },
    selectDashboardPanel(dashboardPanel) {
      // this.$ga.set("dimension1", this.project.id);
      // this.$ga.event({
      //   eventCategory: "Dashboard",
      //   eventAction: this.userAuthenticated.attributes.email,
      //   eventLabel: dashboardPanel.name
      // });
      this.$store.commit("setDashboardPanel", dashboardPanel);
      this.$router.push({ path: "/dashboard" });
      this.$store.commit("setDomain", this.project.domain);
    },
    filter(array, name) {
      return array.reduce((r, o) => {
        var children;
        children = this.filter(o.children || [], name);

        if (children.length) {
          r.push(Object.assign({}, o, { children }));
        }
        if (name.indexOf(o.id) !== -1) {
          r.push(o);
          if (o.children && !name.indexOf(o.children.id)) {
            r[0].children = [];
          }
        }

        return r;
      }, []);
    },

    async fetch() {
      let filters = await UserProject.get(
        this.userAuthenticated.username,
        this.id
      )
        .then(res => res.json())
        .then(res => {
          //console.log("res", res);
          let response = res.length > 0 ? res[0].project_filters : [];
          return response;
        });

      Projects.get(this.id)
        .then(res => res.text())
        .then(res => {
          try {
            let customProject = JSON.parse(res);
            if (filters.length > 0) {
              let filteredLevels = this.filter(customProject.levels, filters);
              customProject.levels = filteredLevels;
            }
            this.project = customProject;
            if (this.project.levels.length === 1) {
              this.selectDashboardPanel(this.project.levels[0]);
            } else {
            //   this.project.levels.map(el => {
            //     if (el.cardBackground) {
            //       let imgRepo = Bucket.getSignedUrl(
            //         this.project.id,
            //         el.cardBackground.value
            //       );
            //       el["imgUrl"] = imgRepo;
            //     }
            //   });
            //   this.loading = false;
            // }

            Promise.all(
              this.project.levels.map(async el => {
                if (el.cardBackground) {
                  let imgRepo = await Bucket.getSignedUrl(
                    this.project.id,
                    el.cardBackground.value
                  );
                  await this.preloadImage(imgRepo);
                  el["imgUrl"] = imgRepo;

                }
              }));
              this.imagesLoaded = true;             

              // Adicionar um pequeno atraso com setTimeout
              setTimeout(() => {
                this.$forceUpdate();
                this.loading = false;
             }, 4000);


            }

          } catch (error) {
            console.log(error);
            this.logOut();
          }
        });
    },
    onResize() {
      this.isMobile = window.innerWidth < 600;
    }
  },
  async mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
    await this.CURRENT_AUTHENTICATED_USER();
    this.$store.commit("defaultColors");
    this.fetch();
    this.$store.commit("setDashboardId", this.id);
  },
  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, { passive: true });
    }
  }
};
</script>

<style scoped>
.card {
  cursor: pointer;
}

#sandbox {
  width: 100% !important;
}

#content_panel {
  padding: 0 !important;
}

.card-img {
  max-width: 100%;
  background-size: cover;
}

.card-img .card-text {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(100, 100, 100, 0.5);
  height: 100%;
}

.card-img .card-text:hover {
  background: rgba(100, 100, 100, 0.1);
}

/* @media only screen and (max-device-width: 678px) {
  .responsiveLogo {
    width: 30%;
    display: block;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 769px) {
  .responsiveLogo {
    width: 30%;
    display: block;
    margin: 0 auto;
  }
} */
</style>