<template>
  <v-window-item value="confirmation-code">
    <v-form :value="formValid">
      <v-card-text>
        <v-text-field
          required
          label="Nova senha"
          type="password"
          :color="colors.loginScreenFormInputColors"
          :rules="[cantEmptyRule, passwordValidRule]"
          :value="form.password"
          @input="e => CHANGE_FORM({input: 'password', value: e})"
        ></v-text-field>
        <v-text-field
          required
          :rules="[cantEmptyRule, passwordValidRule, passwordConfirmationRule]"
          :color="colors.loginScreenFormInputColors"
          label="Confirme a nova senha"
          type="password"
          :value="form.passwordConfirm"
          @input="e => CHANGE_FORM({input: 'passwordConfirm', value: e})"
        ></v-text-field>
        <v-text-field
          label="Código de confirmação enviado para o seu e-mail"
          :color="colors.loginScreenFormInputColors"
          :value="form.confirmationCode"
          :rules="[e => !!e || 'Digite o codigo enviado para o email', e => e.length === 6 || 'O codigo contém 6 digitos']"
          @input="e => CHANGE_FORM({input: 'confirmationCode', value: e})"
        ></v-text-field>
        <!-- <span
          class="caption grey--text text--darken-1"
        >Insira o código de confirmação enviado para o seu e-mail</span> -->
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :style="{ color: colors.loginScreenFormPrimaryButtonsText, backgroundColor: colors.loginScreenFormPrimaryButtons }"
          @click="(formValid && !forgotSteps) ? CODE_CONFIRM_REGISTER() : FORGOT_PASSWORD_SUBMIT()"
          :loading="loader"
          :disabled="!formValid || loader"
          depressed
        >Entrar</v-btn>
      </v-card-actions>
    </v-form>
  </v-window-item>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";

export default {
  name: "ConfirmationCode",
  computed: {
    ...mapState("Cognito", ["form", "formValid", "loader", "forgotSteps"]),
    ...mapGetters("Cognito", [
      "cantEmptyRule",
      "passwordValidRule",
      "passwordConfirmationRule"
    ]),
    colors() {
      return this.$store.state.colors;
    }
  },
  methods: {
    ...mapMutations("Cognito", [
      "CHANGE_FORM",
      "CHANGE_STEP",
      "CHANGE_FORM_VALID"
    ]),
    ...mapActions("Cognito", [
      "CODE_CONFIRM_REGISTER",
      "FORGOT_PASSWORD_SUBMIT"
    ])
  }
};
</script>

<style>
</style>