
import Auth from "./_auth"

const api = 'https://xiarf26imk.execute-api.us-east-1.amazonaws.com'
const stage = 'dev'

const url = (id) => `${api}/${stage}/users/${id || ''}`

export default {
    async getAllPagination(paginationToken) {
        const token = (await Auth.token()).getIdToken().jwtToken

        const params = {
            method: 'GET',
            headers: {
                "Content-type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${token}`
            }
        }

        if (paginationToken) {
            const paginationTokenBase64 = new Buffer(paginationToken).toString("base64")
            return fetch(`https://xiarf26imk.execute-api.us-east-1.amazonaws.com/dev/users-pg?paginationToken=${paginationTokenBase64}`, params)
        }

        return fetch("https://xiarf26imk.execute-api.us-east-1.amazonaws.com/dev/users-pg", params)
    },

    async getAll() {
        const token = (await Auth.token()).getIdToken().jwtToken

        const params = {
            method: 'GET',
            headers: {
                "Content-type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${token}`
            }
        }
        return fetch(url(), params)
    },
    async get(id) {
        const token = (await Auth.token()).getIdToken().jwtToken

        const params = {
            method: 'GET',
            headers: {
                "Content-type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${token}`
            }
        }
        return fetch(url(id), params)
    },
    async listGroups() {
        const token = (await Auth.token()).getIdToken().jwtToken

        const params = {
            method: 'GET',
            headers: {
                "Content-type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${token}`
            },
        }
        return fetch(`${api}/${stage}/groups/`, params)
    },
    async listGroupsForUser(id) {
        const token = (await Auth.token()).getIdToken().jwtToken

        const params = {
            method: 'GET',
            headers: {
                "Content-type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${token}`
            },
        }
        return fetch(`${url(id)}/groups`, params)
    },
    async createUser(user) {
        const token = (await Auth.token()).getIdToken().jwtToken

        const params = {
            method: 'POST',
            headers: {
                "Content-type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify(user)
        }
        return fetch(url(), params)
    },
    async enableUser(user) {
        const token = (await Auth.token()).getIdToken().jwtToken

        const params = {
            method: 'POST',
            headers: {
                "Content-type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${token}`
            }
        }
        return fetch(`${url(user.Username)}/enable`, params)
    },
    async disableUser(user) {
        const token = (await Auth.token()).getIdToken().jwtToken

        const params = {
            method: 'POST',
            headers: {
                "Content-type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${token}`
            }
        }
        return fetch(`${url(user.Username)}/disable`, params)
    },
    async deleteUser(user) {
        const token = (await Auth.token()).getIdToken().jwtToken

        const params = {
            method: 'DELETE',
            headers: {
                "Content-type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${token}`
            }
        }
        return fetch(url(user.Username), params)
    },
    async resetUserPassword(user) {
        const token = (await Auth.token()).getIdToken().jwtToken

        const params = {
            method: 'POST',
            headers: {
                "Content-type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${token}`
            }
        }
        return fetch(`${url(user.Username)}/reset`, params)
    },
    async addUserToGroup(user, groups) {
        const token = (await Auth.token()).getIdToken().jwtToken

        const params = {
            method: 'POST',
            headers: {
                "Content-type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify(groups)
        }
        return fetch(`${url(user.Username)}/groups`, params)
    },
    async removeUserFromGroup(user, groups) {
        const token = (await Auth.token()).getIdToken().jwtToken

        const params = {
            method: 'DELETE',
            headers: {
                "Content-type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify(groups)
        }
        return fetch(`${url(user.Username)}/groups`, params)
    },
}