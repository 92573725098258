<template>
  <v-window-item value="login">
    <v-form ref="form-login-email" :value="formValid">
      <v-card-text>
        <v-text-field
          label="E-mail"
          type="email"
          :color="colors.loginScreenFormInputColors"
          :value="form.email"
          :rules="[cantEmptyRule, emailValidRule]"
          @input="(e) => CHANGE_FORM({ input: 'email', value: e })"
        ></v-text-field>
        <v-text-field
          label="Senha"
          type="password"
          :color="colors.loginScreenFormInputColors"
          :rules="[cantEmptyRule]"
          :value="form.password"
          @keyup.enter="logIn"
          @input="(e) => CHANGE_FORM({ input: 'password', value: e })"
        ></v-text-field>
        <span class="caption grey--text text--darken-1">
          <a
            @click="[CHANGE_STEP('forgot-password')]"
            :style="{ color: colors.loginScreenFormForgotPasswordText }"
            >Esqueci minha senha</a
          >
        </span>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <div
          class="g-recaptcha"
          v-if="gCaptcha"
          id="recaptcha"
          data-callback="showLoginButton"
        ></div>
        <!-- </div> -->
        <v-btn
          v-else
          :disabled="!formValid || loader"
          :loading="loader"
          :style="{
            color: colors.loginScreenFormPrimaryButtonsText,
            backgroundColor: colors.loginScreenFormPrimaryButtons,
          }"
          @click="logIn"
          depressed
          >Entrar</v-btn
        >
      </v-card-actions>
    </v-form>
  </v-window-item>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import Cognito from "../../../store/cognito";

export default {
  name: "Login",
  data() {
    return {
      gCaptcha: true,
    };
  },
  computed: {
    ...mapState("Cognito", [
      "form",
      "formValid",
      "loader",
      "userAuthenticated",
      "alert",
      "messageError",
      "steps",
    ]),
    ...mapGetters("Cognito", [
      "passwordValidRule",
      "emailValidRule",
      "cantEmptyRule",
    ]),
    colors() {
      return this.$store.state.colors;
    },
  },
  methods: {
    ...mapMutations("Cognito", [
      "CHANGE_STEP",
      "CHANGE_FORM",
      "CHANGE_FORM_VALID",
      "CHANGE_ERROR",
    ]),
    ...mapActions("Cognito", ["SIGNIN", "NEW_PASSWORD_REQUIRED"]),
    logIn() {
      const self = this;
      if (!this.formValid) return;
      this.SIGNIN()
        .then((res) => {
          if (
            self.userAuthenticated.challengeName !== null &&
            self.userAuthenticated.challengeName === "NEW_PASSWORD_REQUIRED"
          ) {
            //console.log(res);
            self.CHANGE_ERROR("Por favor, altere sua senha temporária.");
            self.CHANGE_STEP("new-account-password");
            // self
            //   .NEW_PASSWORD_REQUIRED()
            //   .then(res => {
            //     console.log("res dentro de new pass", res);
            //   })
            //   .catch(err => console.log("err new pass", err));
          } else if (self.userAuthenticated) {
            localStorage.setItem(
              "pad_email",
              Cognito.state.userAuthenticated.attributes.email
            );
            self.$router.push({ path: "home" });
          }
        })
        .catch((err) => console.error(err));
    },
    showLoginButton() {
      this.gCaptcha = false;
    },
  },
  mounted() {
    let reCaptchaScript = document.createElement("script");
    reCaptchaScript.setAttribute(
      "src",
      "https://www.google.com/recaptcha/api.js"
    );
    let captchaDiv = document.getElementById("recaptcha");
    captchaDiv.setAttribute(
      "data-sitekey",
      "6LdKzeAUAAAAAPFkaxZ9_u9fjsf0R7Ba9_KjSNzu"
    );
    reCaptchaScript.async = true;
    reCaptchaScript.defer = true;
    document.head.appendChild(reCaptchaScript);
    window.showLoginButton = this.showLoginButton;
    //console.log(document.getElementsByTagName("iframe"));

    let cookiesScript = document.createElement("script");
    cookiesScript.setAttribute(
      "src",
      "//tag.goadopt.io/injector.js?website_code=499c599d-f08d-4627-aec3-5444fcd9ea8a"
    );
    cookiesScript.setAttribute("class", "adopt-injector");
    document.head.appendChild(cookiesScript);
  },
};
</script>