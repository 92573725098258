import "babel-polyfill";
import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Vuelidate from "vuelidate";
import * as AmplifyModules from "aws-amplify";
import { AmplifyPlugin } from "aws-amplify-vue";
require("es6-promise").polyfill();
require("isomorphic-fetch");

// import VueAnalytics from "vue-analytics";

Vue.use(AmplifyPlugin, AmplifyModules);

Vue.config.productionTip = false;

// Vue.use(VueAnalytics, {
//   id: "UA-136367582-2",
// });
new Vue({
  router,
  store,
  vuetify,
  Vuelidate,
  render: (h) => h(App),
}).$mount("#app");
