import Vue from "vue";
import VueRouter from "vue-router";

// Login
import Login from "../views/auth/Login.vue";
import CustomLogin from "../views/auth/CustomLogin.vue";
import Home from "../views/auth/Home.vue";

// Admin
import Admin from "../views/admin/Admin.vue";
import Projects from "../components/admin/Projects.vue";
import Users from "../components/admin/Users.vue";
import CreateProject from "../components/admin/projects/Create.vue";
import UpdateProject from "../components/admin/projects/Update.vue";
import PreviewProject from "../components/admin/projects/Preview.vue";
import Update from "../components/admin/users/Update.vue";

// Client
import Dashboard from "../views/client/Dashboard.vue";
import Tableau from "../components/client/Tableau.vue";
import Project from "../views/client/Project.vue";

// Cognito
import cognito from "../repositories/cognito-user";

// import VueAnalytics from "vue-analytics";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      title: "Login",
    },
  },
  {
    path: "/:domain/login",
    component: CustomLogin,
    meta: {
      title: "Login",
    },
  },
  {
    path: "/home",
    name: "home",
    component: Home,
    meta: { title: "Início", requiresAuth: true },
  },
  {
    path: "/project/:id",
    name: "project",
    component: Project,
    meta: { title: "Projeto - Início", requiresAuth: true },
  },
  {
    path: "/admin",
    component: Admin,
    children: [
      {
        path: "",
        redirect: "projects",
      },
      {
        path: "projects",
        component: Projects,
        meta: {
          title: "Projetos",
        },
      },
      {
        path: "projects/create",
        component: CreateProject,
        meta: {
          title: "Criar projeto",
        },
      },
      {
        path: "projects/:id/update",
        component: UpdateProject,
        children: [
          {
            path: "",
            component: PreviewProject,
            meta: {
              title: "Atualizar projeto",
            },
          },
        ],
      },
      {
        path: "users",
        component: Users,
        meta: {
          title: "Usuários",
        },
      },
      {
        path: "users/:id/update",
        component: Update,
        meta: {
          title: "Atualizar usuário",
        },
      },
    ],
    meta: { title: "Painel de administração", admin: true, requiresAuth: true },
  },
  {
    path: "/dashboard",
    component: Dashboard,
    children: [
      {
        path: "",
        component: Tableau,
        meta: { title: "Painel de dashboards" },
      },
    ],
    meta: { title: "Painel de dashboards", requiresAuth: true },
  },
];

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// Vue.use(VueAnalytics, {
//   id: "UA-136367582-2",
// });

function isAuthenticated() {
  return Vue.prototype.$Amplify.Auth.currentAuthenticatedUser();
}

async function role(user) {
  const groups = await cognito
    .listGroupsForUser(user.username, user.signInUserSession.idToken.jwtToken)
    .then((res) => res.json())
    .then((res) => res.Groups);

  let result = "";

  groups.forEach((group) => {
    if (result === "god" || result === "admin") return;
    result = group.GroupName;
  });

  result =
    result === "" || (result !== "god" && result !== "admin")
      ? "viewer"
      : result;

  return result;
}

router.beforeResolve(async (to, from, next) => {
  document.title = to.meta.title;

  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const admin = to.matched.some((record) => record.meta.admin);

  try {
    const auth = await isAuthenticated();
    if (!requiresAuth) next("/home");
    else {
      const permission = await role(auth);
      if (admin && permission == "viewer") {
        next("/home");
      }
    }
  } catch (error) {
    if (requiresAuth) next("/");
  }
  next();
});

export default router;
