import Auth from "./_auth"

const api = 'https://ri66ce3k62.execute-api.us-east-1.amazonaws.com/dev/'

export default {
    async getAll() {
        const token = (await Auth.token()).getIdToken().jwtToken

        const params = {
            method: 'GET',
            headers: {
                "Content-type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${token}`
            }
        }
        return fetch(api, params)
    },
    async getById(id) {
        const token = (await Auth.token()).getIdToken().jwtToken

        const params = {
            method: 'GET',
            headers: {
                "Content-type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${token}`
            }
        }
        return fetch(`${api}user/${id}`, params)
    },
    async countUser(user) {
        const token = (await Auth.token()).getIdToken().jwtToken

        const params = {
            method: 'POST',
            headers: {
                "Content-type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify(
                {
                    "user_id": user
                }
            )
        }
        return fetch(api, params)
    }
}
