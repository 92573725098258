<template>
  <v-container grid-list-md text-xs-center>
    <v-row>
      <v-col cols="4" justify="center" align="center">
        <h3>Página de Login</h3>
        <v-layout>
          <v-flex xs6>
            <v-sheet :color="colors.loginScreenBackground">
              <span :style="{color: 'white'}">Tela de login</span>
            </v-sheet>
            <v-text-field
              @focus="type='loginScreenBackground'"
              v-model="colors.loginScreenBackground"
              label="Regular"
            ></v-text-field>
          </v-flex>
          <v-flex xs6>
            <v-sheet>
              <span>Logo</span>
            </v-sheet>
            <v-combobox v-model="logo" :items="files" label="Imagem"></v-combobox>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs6>
            <v-sheet :color="colors.loginScreenFormBackground">
              <span :style="{color: invert(colors.loginScreenFormBackground)}">Cor do formulário</span>
            </v-sheet>
            <v-text-field
              @focus="type='loginScreenFormBackground'"
              v-model="colors.loginScreenFormBackground"
              label="Regular"
            ></v-text-field>
          </v-flex>
          <v-flex xs6>
            <v-sheet :color="colors.loginScreenFormTitleText">
              <span :style="{color: invert(colors.loginScreenFormTitleText)}">Titulo do formulário</span>
            </v-sheet>
            <v-text-field
              @focus="type='loginScreenFormTitleText'"
              v-model="colors.loginScreenFormTitleText"
              label="Regular"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs6>
            <v-sheet :color="colors.loginScreenFormAlertBackground">
              <span
                :style="{color: invert(colors.loginScreenFormAlertBackground)}"
              >Alerta no formulário</span>
            </v-sheet>
            <v-text-field
              @focus="type='loginScreenFormAlertBackground'"
              v-model="colors.loginScreenFormAlertBackground"
              label="Regular"
            ></v-text-field>
          </v-flex>
          <v-flex xs6>
            <v-sheet :color="colors.loginScreenFormSubtitles">
              <span :style="{color: invert(colors.loginScreenFormSubtitles)}">Legendas: Formulário</span>
            </v-sheet>
            <v-text-field
              @focus="type='loginScreenFormSubtitles'"
              v-model="colors.loginScreenFormSubtitles"
              label="Regular"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs6>
            <v-sheet :color="colors.loginScreenFormPrimaryButtons">
              <span
                :style="{color: invert(colors.loginScreenFormPrimaryButtons)}"
              >Botões primários: Formulário</span>
            </v-sheet>
            <v-text-field
              @focus="type='loginScreenFormPrimaryButtons'"
              v-model="colors.loginScreenFormPrimaryButtons"
              label="Regular"
            ></v-text-field>
          </v-flex>
          <v-flex xs6>
            <v-sheet :color="colors.loginScreenFormInputColors">
              <span :style="{color: invert(colors.loginScreenFormInputColors)}">Campos do formulário</span>
            </v-sheet>
            <v-text-field
              @focus="type='loginScreenFormInputColors'"
              v-model="colors.loginScreenFormInputColors"
              label="Regular"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs6>
            <v-sheet :color="colors.loginScreenFormPrimaryButtonsText">
              <span
                :style="{color: invert(colors.loginScreenFormPrimaryButtonsText)}"
              >Texto: botões primários</span>
            </v-sheet>
            <v-text-field
              @focus="type='loginScreenFormPrimaryButtonsText'"
              v-model="colors.loginScreenFormPrimaryButtonsText"
              label="Regular"
            ></v-text-field>
          </v-flex>
          <v-flex xs6>
            <v-sheet :color="colors.loginScreenFormSecondaryButtons">
              <span
                :style="{color: invert(colors.loginScreenFormSecondaryButtons)}"
              >Botões secundários: Formulário</span>
            </v-sheet>
            <v-text-field
              @focus="type='loginScreenFormSecondaryButtons'"
              v-model="colors.loginScreenFormSecondaryButtons"
              label="Regular"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs6>
            <v-sheet :color="colors.loginScreenFormSecondaryButtonsText">
              <span
                :style="{color: invert(colors.loginScreenFormSecondaryButtonsText)}"
              >Texto: botões secundários</span>
            </v-sheet>
            <v-text-field
              @focus="type='loginScreenFormSecondaryButtonsText'"
              v-model="colors.loginScreenFormSecondaryButtonsText"
              label="Regular"
            ></v-text-field>
          </v-flex>
          <v-flex xs6>
            <v-sheet :color="colors.loginScreenFormForgotPasswordText">
              <span
                :style="{color: invert(colors.loginScreenFormForgotPasswordText)}"
              >Texto "Esqueceu sua senha?"</span>
            </v-sheet>
            <v-text-field
              @focus="type='loginScreenFormForgotPasswordText'"
              v-model="colors.loginScreenFormForgotPasswordText"
              label="Regular"
            ></v-text-field>
          </v-flex>
        </v-layout>
      </v-col>
      <v-col cols="2" justify="center" align="center">
        <h3>Ancoragem</h3>
        <v-layout>
          <v-flex xs12>
            <v-sheet>
              <span>Logo</span>
            </v-sheet>
            <v-combobox v-model="startScreenLogo" :items="files" label="Imagem"></v-combobox>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-sheet>
              <span>Alinhamento da logo</span>
            </v-sheet>
            <v-radio-group v-model="colors.startScreenLogoAlign">
              <v-radio :label="`Esquerda`"></v-radio>
              <v-radio :label="`Centro`"></v-radio>
              <v-radio :label="`Direira`"></v-radio>
            </v-radio-group>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-sheet :color="colors.startScreen">
              <span :style="{color: invert(colors.startScreen)}">Tela inicial</span>
            </v-sheet>
            <v-text-field @focus="type='startScreen'" v-model="colors.startScreen" label="Regular"></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-sheet :color="colors.startCards">
              <span :style="{color: invert(colors.startCards)}">Caixas da tela inicial</span>
            </v-sheet>
            <v-text-field @focus="type='startCards'" v-model="colors.startCards" label="Regular"></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-sheet :color="colors.startText">
              <span :style="{color: invert(colors.startText)}">Texto da tela inicial</span>
            </v-sheet>
            <v-text-field @focus="type='startText'" v-model="colors.startText" label="Regular"></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-sheet :color="colors.startTextCard">
              <span :style="{color: invert(colors.startTextCard)}">Texto da tela inicial (caixas)</span>
            </v-sheet>
            <v-text-field
              @focus="type='startTextCard'"
              v-model="colors.startTextCard"
              label="Regular"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-sheet :color="colors.startTextCardHover">
              <span
                :style="{color: invert(colors.startTextCardHover)}"
              >Texto da tela inicial (caixas ativas)</span>
            </v-sheet>
            <v-text-field
              @focus="type='startTextCardHover'"
              v-model="colors.startTextCardHover"
              label="Regular"
            ></v-text-field>
          </v-flex>
        </v-layout>
      </v-col>
      <v-col cols="2" justify="center" align="center">
        <h3>Dashboards</h3>
        <v-layout>
          <v-flex xs12>
            <v-sheet>
              <span>Incluir logo?</span>
            </v-sheet>
            <v-checkbox
              v-model="colors.isMenuLogoAllowed"
              :label="colors.isMenuLogoAllowed === true ? 'Sim' : 'Não'"
            ></v-checkbox>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-sheet>
              <span>Logo</span>
            </v-sheet>
            <v-combobox v-model="menuLogo" :items="files" label="Imagem"></v-combobox>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-sheet :color="colors.icons">
              <span :style="{color: invert(colors.icons)}">Ícones</span>
            </v-sheet>
            <v-text-field @focus="type='icons'" v-model="colors.icons" label="Regular"></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-sheet :color="colors.startCardsHover">
              <span :style="{color: invert(colors.startCardsHover)}">Caixas da tela inicial (ativo)</span>
            </v-sheet>
            <v-text-field
              @focus="type='startCardsHover'"
              v-model="colors.startCardsHover"
              label="Regular"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-sheet :color="colors.appBar">
              <span :style="{color: invert(colors.appBar)}">Barra superior</span>
            </v-sheet>
            <v-text-field @focus="type='appBar'" v-model="colors.appBar" label="Regular"></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-sheet :color="colors.text">
              <span :style="{color: invert(colors.text)}">Texto do menu lateral (ativo)</span>
            </v-sheet>
            <v-text-field @focus="type='text'" v-model="colors.text" label="Regular"></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-sheet :color="colors.menu">
              <span :style="{color: invert(colors.menu)}">Menu lateral</span>
            </v-sheet>
            <v-text-field @focus="type='menu'" v-model="colors.menu" label="Regular"></v-text-field>
          </v-flex>
        </v-layout>
      </v-col>
      <v-col justify="center" align="center" cols="4">
        <v-color-picker :light="true" v-model="color" show-swatches></v-color-picker>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      type: "appBar",
      swatches: [
        ["#FF0000", "#AA0000", "#550000"],
        ["#FFFF00", "#AAAA00", "#555500"],
        ["#00FF00", "#00AA00", "#005500"],
        ["#00FFFF", "#00AAAA", "#005555"],
        ["#0000FF", "#0000AA", "#000055"]
      ],
      logo: null,
      startScreenLogo: null,
      menuLogo: null
    };
  },
  computed: {
    files() {
      const files = this.$store.state.files;
      return files.map(file => {
        const split = file.Key.split("/");
        const text = split[split.length - 1];
        return { text, value: file };
      });
    },
    colors() {
      return this.$store.state.colors;
    },
    color: {
      get() {
        return this.colors[this.type];
      },
      set(v) {
        this.$store.commit("setColor", { [this.type]: v });
      }
    }
  },
  watch: {
    menuLogo(file) {
      if (file) {
        this.$store.commit("setMenuLogo", file.value.Key);
      }
    },
    startScreenLogo(file) {
      if (file) {
        this.$store.commit("setAnchorPageLogo", file.value.Key);
      }
    },
    logo(file) {
      if (file) {
        //console.log("logo in colors", file.value.key);
        this.$store.commit("setLogo", file.value.Key);
      }
    }
  },
  methods: {
    invert(value) {
      value = value.substring(1);
      value = value.toUpperCase();
      let splitnum = value.split("");
      let resultnum = "";
      let simplenum = "FEDCBA9876".split("");
      let complexnum = new Array();
      complexnum.A = "5";
      complexnum.B = "4";
      complexnum.C = "3";
      complexnum.D = "2";
      complexnum.E = "1";
      complexnum.F = "0";

      for (let i = 0; i < 6; i++) {
        if (!isNaN(splitnum[i])) {
          resultnum += simplenum[splitnum[i]];
        } else if (complexnum[splitnum[i]]) {
          resultnum += complexnum[splitnum[i]];
        } else {
          return "#424242";
        }
      }
      return "#" + resultnum;
    }
  }
};
</script>

<style>
</style>