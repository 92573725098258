<template>
  <v-row id="row" :align="$vuetify.breakpoint.mdAndUp ? 'center' : 'start'">
    <v-card
      :class="{'mx-12': $vuetify.breakpoint.smOnly}"
      width="100%"
      :color="colors.loginScreenFormBackground"
    >
      <v-card-title class="title font-weight-regular justify-space-between">
        <span :style="{ color: colors.loginScreenFormTitleText }">{{ currentWindow.title }}</span>
        <v-alert
          elevation
          :value="alert"
          :color="colors.loginScreenFormAlertBackground"
          type="error"
          dismissible
          transition="scale-transition"
        >{{ messageError }}</v-alert>
      </v-card-title>
      <v-card-subtitle class="subtitle font-weight-regular justify-space-between">
        <span>{{ currentWindow.subtitle }}</span>
      </v-card-subtitle>
      <v-window :value="step" @input="e => this.CHANGE_STEP(e)" :touchless="true">
        <Login ref="form-login" />
        <RegisterEmail ref="form-register-email" />
        <RegisterPassword ref="form-register-password" />
        <ForgotPassword ref="form-forgot-password" />
        <ConfirmationCode ref="form-confirmation-code" />
        <NewAccountPassword ref="form-new-account-password" />
      </v-window>
    </v-card>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";

import Login from "./form/Login";
import RegisterEmail from "./form/RegisterEmail";
import RegisterPassword from "./form/RegisterPassword";
import ForgotPassword from "./form/ForgotPassword";
import ConfirmationCode from "./form/ConfirmationCode";
import NewAccountPassword from "./form/NewAccountPassword";

export default {
  components: {
    Login,
    RegisterEmail,
    RegisterPassword,
    ForgotPassword,
    ConfirmationCode,
    NewAccountPassword
  },
  computed: {
    ...mapGetters("Cognito", ["currentWindow"]),
    ...mapState("Cognito", [
      "step",
      "userAuthenticated",
      "form",
      "messageError",
      "alert"
    ]),
    colors() {
      return this.$store.state.colors;
    }
  },
  methods: {
    ...mapMutations("Cognito", ["CHANGE_FORM", "CHANGE_FORM_VALID"])
  },
  watch: {
    form: {
      deep: true,
      handler: function() {
        if (
          this.$refs[`form-${this.step}`].$children[0].$children[0].validate()
        ) {
          this.CHANGE_FORM_VALID(
            this.$refs[`form-${this.step}`].$children[0].$children[0].validate()
          );
        } else {
          this.CHANGE_FORM_VALID(
            this.$refs[`form-${this.step}`].$children[0].$children[0].validate()
          );
        }
      }
    },
    step(newValue, oldValue) {
      if (this.$refs[`form-${oldValue}`].$children[0].$children[0]) {
        this.CHANGE_FORM_VALID(false);
        this.$refs[
          `form-${oldValue}`
        ].$children[0].$children[0].resetValidation();
      }
      if (this.$refs[`form-${newValue}`].$children[0].$children[0]) {
        this.CHANGE_FORM_VALID(false);
        this.$refs[
          `form-${newValue}`
        ].$children[0].$children[0].resetValidation();
      }
      if (oldValue === "login" && newValue === "register-email") {
        this.CHANGE_FORM({ input: "password", value: "" });
        this.CHANGE_FORM({ input: "passwordConfirm", value: "" });
      }
      if (
        (oldValue === "register-password" && newValue === "register-email") ||
        (oldValue === "register-password" && newValue === "forgot-password") ||
        (oldValue === "login" && newValue === "register-email") ||
        (oldValue === "login" && newValue === "forgot-password") ||
        (oldValue === "login" && newValue === "new-account-password")
      ) {
        this.CHANGE_FORM({ input: "password", value: "" });
        this.CHANGE_FORM({ input: "passwordConfirm", value: "" });
      }
      if (oldValue === "confirmation-code" && newValue === "login") {
        this.CHANGE_FORM({ input: "confirmationCode", value: "" });
      }
    }
  }
};
</script>