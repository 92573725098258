<template>
  <v-container>
    <div v-if="projectCreation">
      <v-row>
        <v-col cols="4">
          <h2 class="mb-2">Dados do projeto</h2>
          <v-card>
            <v-card-text>
              <v-form>
                <v-text-field label="Nome do projeto" v-model="name"></v-text-field>
                <v-text-field label="Domínio" v-model="domain" :rules="rules.domainRule"></v-text-field>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="8">
          <h2 class="mb-2">Arquivos</h2>
          <v-card>
            <Files />
          </v-card>
        </v-col>
      </v-row>
      <h2>Estrutura</h2>
    </div>
    <v-row align="start" justify="space-around">
      <v-dialog max-width="500" v-model="create">
        <v-card class="mx-auto" max-width="500">
          <v-card-title class="title font-weight-regular justify-space-between">
            <span>{{ currentTitle }}</span>
            <!-- <v-avatar
              class="subheading white--text"
              size="24"
              v-text="step"
            ></v-avatar>-->
          </v-card-title>

          <v-window v-model="step">
            <!-- <v-window-item :value="1">
              <v-radio-group v-model="column" column>
                <v-radio label="Level" value="level"></v-radio>
                <v-radio label="Dashboard" value="dashboard"></v-radio>
              </v-radio-group>
            </v-window-item>-->

            <v-window-item :value="1">
              <v-card-text>
                <!-- <v-row v-if="column === 'level'">
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="level.name" label="Level"></v-text-field>
                  </v-col>
                </v-row>-->
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="dashboard.name" label="Dashboard"></v-text-field>
                  </v-col>
                  <!-- <v-col cols="12" sm="6" md="4">
                    <v-combobox v-model="downloadButton.url" :items="files" label="Arquivo"></v-combobox>
                  </v-col>-->
                </v-row>
              </v-card-text>
            </v-window-item>
            <v-window-item :value="2">
              <v-card-text>
                <!-- <v-row v-if="column === 'level'">
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="level.name" label="Level"></v-text-field>
                  </v-col>
                </v-row>-->
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-combobox v-model="dashboard.cardBackground" :items="files" label="Arquivo"></v-combobox>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-window-item>
          </v-window>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn :disabled="step === 1" text @click="step--">Voltar</v-btn>
            <v-spacer></v-spacer>
            <v-btn :disabled="step === 2" color="primary" depressed @click="step++">Avançar</v-btn>
            <!-- <v-btn :disabled="step === 2" color="primary" depressed @click="step++">Next</v-btn> -->
            <v-btn :disabled="step === 1" color="primary" depressed @click="save()">Salvar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- dialog create dash items -->
      <v-dialog max-width="500" v-model="dashboardWindow">
        <v-card class="mx-auto" max-width="500">
          <v-card-title class="title font-weight-regular justify-space-between">
            <span>{{ dashWindowTitle }}</span>
            <v-avatar
              color="primary lighten-2"
              class="subheading white--text"
              size="24"
              v-text="dashStep"
            ></v-avatar>
          </v-card-title>

          <v-window v-model="dashStep">
            <v-window-item :value="1">
              <v-radio-group v-model="dashColumn" column>
                <v-radio label="Agrupador" value="group"></v-radio>
                <v-radio label="Botão" value="button"></v-radio>
              </v-radio-group>
            </v-window-item>

            <v-window-item :value="2">
              <v-card-text>
                <v-row v-if="dashColumn === 'group'">
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="group.name" label="Nome do agrupador"></v-text-field>
                    <v-text-field v-model="group.icon" label="Ícone do agrupador"></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="dashColumn === 'button'">
                  <v-radio-group v-model="buttonOpt" column>
                    <v-radio label="Tableau" value="tableau"></v-radio>
                    <v-radio label="Download" value="download"></v-radio>
                    <v-radio label="Link" value="linkButton"></v-radio>
                  </v-radio-group>
                </v-row>
                <!-- <v-row v-if="dashColumn === 'linkButton'">
                  <v-radio-group column>
                    <v-text-field v-model="linkButton.name" label="Nome"></v-text-field>
                    <v-text-field v-model="linkButton.icon" label="Ícone"></v-text-field>
                    <v-text-field v-model="linkButton.link" label="Link"></v-text-field>
                  </v-radio-group>
                </v-row>-->
              </v-card-text>
            </v-window-item>

            <v-window-item :value="3">
              <v-card-text>
                <v-row v-if="buttonOpt === 'tableau'">
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="tableauButton.name" label="Nome"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="tableauButton.folder" label="Folder"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="tableauButton.view" label="View"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="tableauButton.icon" label="Ícone"></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="buttonOpt === 'download'">
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="downloadButton.name" label="Nome"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-combobox v-model="downloadButton.url" :items="files" label="Arquivo"></v-combobox>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="downloadButton.icon" label="Ícone"></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="buttonOpt === 'linkButton'">
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="linkButton.name" label="Nome"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="linkButton.url" label="URL"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="linkButton.icon" label="Ícone"></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-window-item>
          </v-window>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn :disabled="dashStep === 1" text @click="dashStep--">Voltar</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="(dashStep === 3 && (buttonOpt === 'tableau' || buttonOpt === 'download' || buttonOpt === 'linkButton')) || (dashStep === 2 && dashColumn === 'group')"
              color="primary"
              depressed
              @click="dashStep++"
            >Avançar</v-btn>
            <v-btn
              :disabled="dashStep === 1 || (dashStep === 2 && dashColumn === 'button')"
              color="primary"
              depressed
              @click="saveDashboardConfig(dashColumn, buttonOpt, model2)"
            >Salvar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- dialog para criação de agrupadores ou botões dentro de um agrupador. -->
      <v-dialog max-width="500" v-model="dashGroupWindow">
        <v-card class="mx-auto" max-width="500">
          <v-card-title class="title font-weight-regular justify-space-between">
            <span>{{ dashGroupWindowTitle }}</span>
            <v-avatar
              color="primary lighten-2"
              class="subheading white--text"
              size="24"
              v-text="groupStep"
            ></v-avatar>
          </v-card-title>

          <v-window v-model="groupStep">
            <v-window-item :value="1">
              <v-radio-group v-model="groupColumn" column>
                <v-radio label="Agrupador" value="group"></v-radio>
                <v-radio label="Botão" value="button"></v-radio>
              </v-radio-group>
            </v-window-item>

            <v-window-item :value="2">
              <v-card-text>
                <v-row v-if="groupColumn === 'group'">
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="group.name" label="Nome do agrupador"></v-text-field>
                    <v-text-field v-model="group.icon" label="Ícone do agrupador"></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="groupColumn === 'button'">
                  <v-radio-group v-model="buttonOpt" column>
                    <v-radio label="Tableau" value="tableau"></v-radio>
                    <v-radio label="Download" value="download"></v-radio>
                  </v-radio-group>
                </v-row>
              </v-card-text>
            </v-window-item>

            <v-window-item :value="3">
              <v-card-text>
                <v-row v-if="buttonOpt === 'tableau'">
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="tableauButton.name" label="Nome"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="tableauButton.folder" label="Folder"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="tableauButton.view" label="View"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="tableauButton.icon" label="Ícone"></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="buttonOpt === 'download'">
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="downloadButton.name" label="Nome"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-combobox v-model="downloadButton.url" :items="files" label="Arquivo"></v-combobox>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="downloadButton.icon" label="Ícone"></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-window-item>
          </v-window>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn :disabled="groupStep === 1" text @click="groupStep--">Voltar</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="(groupStep === 3 && (buttonOpt === 'tableau' || buttonOpt === 'download')) || (groupStep === 2 && groupColumn === 'group')"
              color="primary"
              depressed
              @click="groupStep++"
            >Avançar</v-btn>
            <v-btn
              :disabled="groupStep === 1 || (groupStep === 2 && groupColumn === 'button')"
              color="primary"
              depressed
              @click="saveGroupConfig(groupColumn, buttonOpt, model3)"
            >Salvar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- fim da dialog -->
      <!-- dialog para criar botão dentro de um agrupador interno (agrupador dentro de agrupador) -->
      <v-dialog max-width="500" v-model="dashGroupButtonWindow">
        <v-card class="mx-auto" max-width="500">
          <v-card-title class="title font-weight-regular justify-space-between">
            <span>{{ dashGroupButtonWindowTitle }}</span>
            <v-avatar
              color="primary lighten-2"
              class="subheading white--text"
              size="24"
              v-text="groupButtonStep"
            ></v-avatar>
          </v-card-title>

          <v-window v-model="groupButtonStep">
            <v-window-item :value="1">
              <v-card-text>
                <v-row>
                  <v-radio-group v-model="buttonGroupOpt" column>
                    <v-radio label="Tableau" value="tableau"></v-radio>
                    <v-radio label="Download" value="download"></v-radio>
                  </v-radio-group>
                </v-row>
              </v-card-text>
            </v-window-item>

            <v-window-item :value="2">
              <v-card-text>
                <v-row v-if="buttonGroupOpt === 'tableau'">
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="tableauButton.name" label="Nome"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="tableauButton.folder" label="Folder"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="tableauButton.view" label="View"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="tableauButton.icon" label="Ícone"></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="buttonGroupOpt === 'download'">
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="downloadButton.name" label="Nome"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-combobox v-model="downloadButton.url" :items="files" label="Arquivo"></v-combobox>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="downloadButton.icon" label="Ícone"></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-window-item>
          </v-window>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn :disabled="groupButtonStep === 1" text @click="groupButtonStep--">Voltar</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="groupButtonStep === 2"
              color="primary"
              depressed
              @click="groupButtonStep++"
            >Avançar</v-btn>
            <v-btn
              :disabled="groupButtonStep === 1"
              color="primary"
              depressed
              @click="saveGroupButtonConfig('button', buttonGroupOpt, model4)"
            >Salvar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- fim da dialog -->
      <v-col v-if="!projectCreation" cols="3">
        <v-card>
          <v-card-title>Novo projeto</v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="valid">
              <v-text-field v-model="name" :counter="50" label="Nome"></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn outlined small color="red" :to="{ path: '/admin/projects' }" exact>Cancelar</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              small
              :disabled="!valid"
              @click="createProject(name)"
              color="primary"
            >Estruturar</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <template v-else>
        <v-row>
          <v-col cols="2">
            <v-list>
              <v-list-item-group mandatory>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-home</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{name}}</v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-icon>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon @click="run" v-on="on">mdi-plus</v-icon>
                      </template>
                      <span>Adicionar painel de dashboards</span>
                    </v-tooltip>
                  </v-list-item-icon>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
          <!-- 2o level -->
          <v-col cols="2" v-if="model2Avaiable">
            <v-list two-line>
              <v-list-item-group v-model="model2" mandatory>
                <v-list-item v-for="(item, i) in this.model[0].levels" :key="i">
                  <v-list-item-icon>
                    <v-icon>mdi-folder</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                    <v-list-item-subtitle v-text="`Itens: ${item.children.length}`"></v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-icon v-if="item.type === 'dashboard'">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon @click="runDashboardWindow" v-on="on">mdi-plus</v-icon>
                      </template>
                      <span>Adicionar itens</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon @click="deleteDashboard(i)" v-on="on">mdi-delete</v-icon>
                      </template>
                      <span>Apagar dashboard</span>
                    </v-tooltip>
                  </v-list-item-icon>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
          <!-- 3o level -->
          <v-col cols="2" v-if="model3Avaiable">
            <v-list two-line>
              <v-list-item-group mandatory v-model="model3">
                <v-list-item v-for="(item, i) in this.model[0].levels[model2].children" :key="i">
                  <v-list-item-icon v-if="item.type === 'group'">
                    <v-icon>mdi-folder</v-icon>
                  </v-list-item-icon>
                  <v-list-item-icon v-if="item.type === 'tableauButton'">
                    <v-icon>mdi-view-dashboard</v-icon>
                  </v-list-item-icon>
                  <v-list-item-icon v-if="item.type === 'downloadButton'">
                    <v-icon>mdi-download</v-icon>
                  </v-list-item-icon>
                  <v-list-item-icon v-if="item.type === 'linkButton'">
                    <v-icon>mdi-link</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                    <v-list-item-subtitle
                      v-if="item.type === 'group'"
                    >Itens: {{item.children.length}}</v-list-item-subtitle>
                    <v-list-item-subtitle v-if="item.type === 'tableauButton'">
                      Folder: {{item.folder}}
                      <br />
                      View: {{item.view}}
                      <br />Ícone:
                      <v-icon>{{item.icon}}</v-icon>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="item.type === 'linkButton'">
                      URL: {{item.url}}
                      <br />Ícone:
                      <v-icon>{{item.icon}}</v-icon>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="item.type === 'downloadButton'">
                      Arquivo: {{item.url.text}}
                      <br />Ícone:
                      <v-icon>{{item.icon}}</v-icon>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-if="item.type === 'group'"
                          @click="runGroupWindow"
                          v-on="on"
                        >mdi-plus</v-icon>
                      </template>
                      <span>Adicionar agrupador ou botão.</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon @click="deleteItem(i)" v-on="on">mdi-delete</v-icon>
                      </template>
                      <span>Remover</span>
                    </v-tooltip>
                  </v-list-item-icon>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
          <!-- 4o level -->
          <v-col cols="2" v-if="model4Avaiable">
            <v-list two-line>
              <v-list-item-group mandatory v-model="model4">
                <v-list-item
                  v-for="(item, i) in this.model[0].levels[model2].children[model3].children"
                  :key="i"
                >
                  <v-list-item-icon v-if="item.type === 'group'">
                    <v-icon>mdi-folder</v-icon>
                  </v-list-item-icon>
                  <v-list-item-icon v-if="item.type === 'tableauButton'">
                    <v-icon>mdi-view-dashboard</v-icon>
                  </v-list-item-icon>
                  <v-list-item-icon v-if="item.type === 'downloadButton'">
                    <v-icon>mdi-download</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                    <v-list-item-subtitle
                      v-if="item.type === 'group'"
                    >Itens: {{item.children.length}}</v-list-item-subtitle>
                    <v-list-item-subtitle v-if="item.type === 'tableauButton'">
                      Folder: {{item.folder}}
                      <br />
                      View: {{item.view}}
                      <br />Ícone:
                      <v-icon>{{item.icon}}</v-icon>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="item.type === 'downloadButton'">
                      Arquivo: {{item.url.text}}
                      <br />Ícone:
                      <v-icon>{{item.icon}}</v-icon>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-if="item.type === 'group'"
                          @click="runGroupButtonWindow"
                          v-on="on"
                        >mdi-plus</v-icon>
                      </template>
                      <span>Adicionar botão</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon @click="deleteGroupItem(i)" v-on="on">mdi-delete</v-icon>
                      </template>
                      <span>Remover</span>
                    </v-tooltip>
                  </v-list-item-icon>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
          <!-- 5o level -->
          <v-col cols="2" v-if="model5Avaiable">
            <v-list two-line>
              <v-list-item-group mandatory v-model="model5">
                <v-list-item
                  v-for="(item, i) in this.model[0].levels[model2].children[model3].children[model4].children"
                  :key="i"
                >
                  <v-list-item-icon v-if="item.type === 'group'">
                    <v-icon>mdi-folder</v-icon>
                  </v-list-item-icon>
                  <v-list-item-icon v-if="item.type === 'tableauButton'">
                    <v-icon>mdi-view-dashboard</v-icon>
                  </v-list-item-icon>
                  <v-list-item-icon v-if="item.type === 'downloadButton'">
                    <v-icon>mdi-download</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                    <v-list-item-subtitle
                      v-if="item.type === 'group'"
                    >Itens: {{item.children.length}}</v-list-item-subtitle>
                    <v-list-item-subtitle v-if="item.type === 'tableauButton'">
                      Folder: {{item.folder}}
                      <br />
                      View: {{item.view}}
                      <br />Ícone:
                      <v-icon>{{item.icon}}</v-icon>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="item.type === 'downloadButton'">
                      Arquivo: {{item.url.text}}
                      <br />Ícone:
                      <v-icon>{{item.icon}}</v-icon>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <!-- <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-if="item.type === 'group'"
                        @click="runGroupButtonWindow"
                        v-on="on"
                      >mdi-plus</v-icon>
                    </template>
                    <span>Adicionar botão</span>
                    </v-tooltip>-->
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon @click="deleteFinalButton(i)" v-on="on">mdi-delete</v-icon>
                      </template>
                      <span>Remover</span>
                    </v-tooltip>
                  </v-list-item-icon>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
          <v-col cols="2">
            <v-row align="center" justify="center">
              <v-btn outlined color="success" @click="post">
                Criar
                <v-icon dark right>mdi-checkbox-marked-circle</v-icon>
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </template>
    </v-row>

    <h2>Customização visual</h2>
    <v-row>
      <v-col cols="12">
        <v-card>
          <Colors />
        </v-card>
      </v-col>
    </v-row>
    <h2>Simulação</h2>
    <v-row>
      <v-col cols="12">
        <v-card>
          <Preview />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import uuid from "uuid/v4";
import Projects from "@/repositories/projects";
import Colors from "@/components/admin/projects/Colors";
import Preview from "@/components/admin/projects/Preview";
import Files from "@/components/admin/projects/Files";

import s3 from "@/repositories/bucket";

import { mapState } from "vuex";

export default {
  components: {
    Colors,
    Preview,
    Files,
  },
  data() {
    return {
      rules: {
        domainRule: [
          (v) => {
            let encoded = encodeURI(v);
            if (v === encoded) {
              return true;
            }
            return "Domínio inválido";
          },
        ],
      },
      domain: "",
      column: null,
      dashColumn: null,
      groupColumn: null,
      buttonOpt: null,
      buttonGroupOpt: null,
      valid: false,
      create: false,
      step: 1,
      dashStep: 1,
      groupStep: 1,
      groupButtonStep: 1,
      model: 0,
      level: {
        name: "",
      },
      dashboard: {
        name: "",
        cardBackground: "",
      },
      group: {
        name: "",
        icon: "",
      },
      linkButton: {
        name: "",
        icon: "",
        type: "linkButton",
        link: "",
      },
      tableauButton: {
        name: "",
        type: "tableauButton",
        folder: "",
        view: "",
        icon: "",
      },
      downloadButton: {
        name: "",
        type: "downloadButton",
        url: "",
        icon: "",
      },
      model2: null,
      model3: null,
      model4: null,
      model5: null,
      name: "",
      projectCreation: false,
      dashboardWindow: false,
      dashGroupWindow: false,
      dashGroupButtonWindow: false,
    };
  },
  methods: {
    cleanDialog() {
      const defaultData = {
        step: 1,
        dashStep: 1,
        groupStep: 1,
        groupButtonStep: 1,
        groupColumn: null,
        dashColumn: null,
        buttonOpt: null,
        dashButton: null,
        buttonGroupOpt: null,
        dashboard: {
          name: "",
          cardBackground: "",
        },
        group: {
          name: "",
          icon: "",
        },
        linkButton: {
          name: "",
          icon: "",
          type: "linkButton",
          link: "",
        },
        tableauButton: {
          name: "",
          type: "tableauButton",
          folder: "",
          icon: "",
          view: "",
        },
        downloadButton: {
          name: "",
          type: "downloadButton",
          icon: "",
          url: "",
        },
      };

      for (let key in defaultData) {
        this[key] = defaultData[key];
      }
    },
    run() {
      this.create = true;
    },
    createProject(name) {
      this.model = [
        {
          name,
          active: true,
          levels: [],
        },
      ];
      this.projectCreation = true;
    },
    createLevel(level) {
      this.model[0].levels.push({
        id: uuid(),
        name: level.name,
        type: "level",
        children: [],
      });
    },
    createDashboard(dash) {
      let data = {
        id: uuid(),
        name: dash.name,
        type: "dashboard",
        cardBackground: dash.cardBackground,
        children: [],
      };
      if (data.cardBackground === "") delete data.cardBackground;
      this.model[0].levels.push(data);
    },
    deleteDashboard(position) {
      let path = this.model[0].levels;
      path.splice(position, 1);
      // delete path[position]
      // this.model = path;
    },
    deleteItem(position) {
      let path = this.model[0].levels[this.model2].children;
      path.splice(position, 1);
    },
    deleteGroupItem(position) {
      let path = this.model[0].levels[this.model2].children[this.model3]
        .children;
      path.splice(position, 1);
    },
    deleteFinalButton(position) {
      let path = this.model[0].levels[this.model2].children[this.model3]
        .children[this.model4].children;
      path.splice(position, 1);
    },
    save() {
      // save recebia column como parâmetro, se column === level,
      // createLevel também era utilizado.
      this.createDashboard(this.dashboard);
      this.create = false;
    },
    runDashboardWindow() {
      this.dashboardWindow = true;
    },
    runGroupWindow() {
      this.dashGroupWindow = true;
    },
    runGroupButtonWindow() {
      this.dashGroupButtonWindow = true;
    },
    saveDashboardConfig(format, opt, position) {
      let path = this.model[0].levels[position].children;
      if (format === "group") {
        this.createGroup(this.group, path);
      } else if (format === "button") {
        if (opt === "tableau") {
          this.createButton(this.tableauButton, path, opt);
        } else if (opt === "download") {
          this.createButton(this.downloadButton, path, opt);
        } else if (opt === "linkButton") {
          this.createButton(this.linkButton, path, opt);
        }
      }
    },
    saveGroupConfig(format, opt, position) {
      let path = this.model[0].levels[this.model2].children[position].children;
      if (format === "group") {
        this.createNewGroup(this.group, path);
      } else if (format === "button") {
        if (opt === "tableau") {
          this.createNewButton(this.tableauButton, path, opt);
        } else if (opt === "download") {
          this.createNewButton(this.downloadButton, path, opt);
        }
      }
    },
    saveGroupButtonConfig(format, opt, position) {
      let path = this.model[0].levels[this.model2].children[this.model3]
        .children[position].children;
      if (format === "button") {
        if (opt === "tableau") {
          this.createNewGroupButton(this.tableauButton, path, opt);
        } else if (opt === "download") {
          this.createNewGroupButton(this.downloadButton, path, opt);
        }
      }
    },
    createNewGroupButton(button, path, opt) {
      if (opt === "tableau") {
        let data = {
          id: uuid(),
          name: button.name,
          type: button.type,
          folder: button.folder,
          icon: button.icon,
          view: button.view,
        };
        if (data.icon === "") delete data.icon;
        path.push(data);
      }
      if (opt === "download") {
        let data = {
          id: uuid(),
          name: button.name,
          type: button.type,
          icon: button.icon,
          url: button.url,
        };
        if (data.icon === "") delete data.icon;
        path.push(data);
      }
      this.dashGroupButtonWindow = false;
    },
    createNewGroup(group, path) {
      let data = {
        id: uuid(),
        name: group.name,
        icon: group.icon,
        type: "group",
        children: [],
      };
      if (data.icon === "") delete data.icon;
      path.push(data);
      this.dashGroupWindow = false;
    },
    createNewButton(button, path, opt) {
      if (opt === "tableau") {
        let data = {
          id: uuid(),
          name: button.name,
          type: button.type,
          folder: button.folder,
          icon: button.icon,
          view: button.view,
        };
        if (data.icon === "") delete data.icon;
        path.push(data);
      }
      if (opt === "download") {
        let data = {
          id: uuid(),
          name: button.name,
          type: button.type,
          icon: button.icon,
          url: button.url,
        };
        if (data.icon === "") delete data.icon;
        path.push(data);
      }
      this.dashGroupWindow = false;
    },
    createGroup(group, path) {
      let data = {
        id: uuid(),
        name: group.name,
        icon: group.icon,
        type: "group",
        children: [],
      };
      if (data.icon === "") delete data.icon;
      path.push(data);
      this.dashboardWindow = false;
    },
    createButton(button, path, opt) {
      if (opt === "tableau") {
        let data = {
          id: uuid(),
          name: button.name,
          type: button.type,
          folder: button.folder,
          icon: button.icon,
          view: button.view,
        };
        if (data.icon === "") delete data.icon;
        path.push(data);
      }
      if (opt === "download") {
        let data = {
          id: uuid(),
          name: button.name,
          type: button.type,
          icon: button.icon,
          url: button.url,
        };
        if (data.icon === "") delete data.icon;
        path.push(data);
      }
      if (opt === "linkButton") {
        let data = {
          id: uuid(),
          name: button.name,
          type: button.type,
          icon: button.icon,
          url: button.url,
        };
        if (data.icon === "") delete data.icon;
        path.push(data);
      }
      this.dashboardWindow = false;
    },
    post() {
      const data = {
        ...this.model[0],
        colors: this.colors,
        domain: this.domain,
      };
      // console.log("projeto criado", data);
      Projects.create(data)
        .then((res) => res.text())
        .then((res) => {
          const response = JSON.parse(res);
          if (this.$store.state.files) {
            this.$store.state.files.forEach((file) => {
              const split = file.Key.split("/");
              const name = split.pop();
              const path = split.join("/");
              s3.moveObject(
                [path, name].join("/"),
                [response.id, name].join("/")
              );
            });
          }
          this.$router.push({ path: "/admin/projects" });
        });
    },
  },
  computed: {
    ...mapState("Cognito", ["client", "userAuthenticated"]),
    files() {
      const files = this.$store.state.files;
      return files.map((file) => {
        const split = file.Key.split("/");
        const text = split[split.length - 1];
        return { text, value: text };
      });
    },
    colors() {
      return this.$store.state.colors;
    },
    model2Avaiable() {
      try {
        let avaiable = false;
        avaiable = this.model[0].levels.length > 0;
        return avaiable;
      } catch (error) {
        return false;
      }
    },
    model3Avaiable() {
      try {
        let avaiable = this.model2Avaiable;
        avaiable =
          avaiable && this.model2 !== null && this.model2 !== undefined;
        avaiable = avaiable && this.model[0].levels.length >= this.model2;
        return avaiable;
      } catch (error) {
        return false;
      }
    },
    model4Avaiable() {
      try {
        let avaiable = this.model3Avaiable;
        avaiable =
          avaiable && this.model3 !== null && this.model3 !== undefined;
        avaiable =
          avaiable &&
          this.model[0].levels[this.model2].children.length >= this.model3;
        avaiable =
          avaiable &&
          this.model[0].levels[this.model2].children[this.model3].type ===
            "group";
        return avaiable;
      } catch (error) {
        return false;
      }
    },
    model5Avaiable() {
      try {
        let avaiable = this.model4Avaiable;
        avaiable =
          avaiable && this.model4 !== null && this.model4 !== undefined;
        avaiable =
          avaiable &&
          this.model[0].levels[this.model2].children[this.model3].children
            .length >= this.model4;
        avaiable =
          avaiable &&
          this.model[0].levels[this.model2].children[this.model3].children[
            this.model4
          ].type === "group";
        return avaiable;
      } catch (error) {
        return false;
      }
    },
    currentTitle() {
      switch (this.step) {
        case 1:
          return "Defina um nome para o painel de dashboards";
        case 2:
          return "Defina um plano de fundo para o card (opcional)";
        default:
          return "created";
      }
    },
    dashWindowTitle() {
      switch (this.dashStep) {
        case 1:
          return "Escolha o tipo de botão";
        case 2:
          return "Selecione uma função para o botão:";
        case 3:
          return "Dados:";
        default:
          return "create";
      }
    },
    dashGroupWindowTitle() {
      switch (this.groupStep) {
        case 1:
          return "Escolha o tipo de botão";
        case 2:
          return "Selecione uma função para o botão:";
        case 3:
          return "Dados:";
        default:
          return "created";
      }
    },
    dashGroupButtonWindowTitle() {
      switch (this.groupButtonStep) {
        case 1:
          return "Escolha a função do seu botão";
        case 2:
          return "Confirme os dados";
        default:
          return "created";
      }
    },
  },
  watch: {
    model: {
      handler(value) {
        this.$store.commit("setModel", value[0]);
      },
      immediate: true,
      deep: true,
    },
    create(active) {
      if (!active) {
        this.cleanDialog();
      }
    },
    dashboardWindow(active) {
      if (!active) {
        this.cleanDialog();
      }
    },
    dashGroupWindow(active) {
      if (!active) {
        this.cleanDialog();
      }
    },
    dashGroupButtonWindow(active) {
      if (!active) {
        this.cleanDialog();
      }
    },
  },
  mounted() {
    this.$store.commit("defaultColors");
  },
};
</script>

<style>
</style>