<template>
  <div>
    <v-container
      v-if="view === 'login'"
      id="container"
      :style="{ backgroundColor: colors.loginScreenBackground }"
      fluid
    >
      <v-row id="row" :class="{ expand : $vuetify.breakpoint.mdAndUp }">
        <v-col
          :class="{ expand : $vuetify.breakpoint.mdAndUp }"
          :cols="$vuetify.breakpoint.mdAndUp ? 7 : 12"
        >
          <v-row id="row" justify="center" align="center">
            <img alt="Logo" :src="logo" />
          </v-row>
        </v-col>
        <v-col
          :class="{ 'px-6': $vuetify.breakpoint.mdAndDown }"
          :cols="$vuetify.breakpoint.mdAndUp ? 5 : 12"
        >
          <v-row id="row" :align="$vuetify.breakpoint.mdAndUp ? 'center' : 'start'">
            <v-card
              :class="{'mx-12': $vuetify.breakpoint.smOnly}"
              width="100%"
              :color="colors.loginScreenFormBackground"
            >
              <v-card-title class="title font-weight-regular justify-space-between">
                <span :style="{ color: colors.loginScreenFormTitleText }">{{ steps.login.title }}</span>
                <v-alert
                  elevation
                  type="error"
                  :color="colors.loginScreenFormAlertBackground"
                  dismissible
                  transition="scale-transition"
                >{{messageError}}</v-alert>
              </v-card-title>
              <v-card-subtitle class="subtitle font-weight-regular justify-space-between">
                <span>{{ steps.login.subtitle }}</span>
              </v-card-subtitle>
              <v-window v-model="step">
                <!-- <Login ref="form-login" /> -->
                <v-window-item value="login">
                  <v-form ref="form-login-email">
                    <v-card-text>
                      <v-text-field
                        :color="colors.loginScreenFormInputColors"
                        :rules="rules.cantEmptyRule"
                        readonly
                        label="E-mail"
                        type="email"
                      ></v-text-field>
                      <v-text-field
                        :color="colors.loginScreenFormInputColors"
                        readonly
                        label="Senha"
                        type="password"
                      ></v-text-field>
                      <span class="caption grey--text text--darken-1">
                        <a
                          @click="step = 'forgot-password'"
                          :style="{ color: colors.loginScreenFormForgotPasswordText }"
                        >Alterar senha</a>
                      </span>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        :style="{ color: colors.loginScreenFormPrimaryButtonsText, backgroundColor: colors.loginScreenFormPrimaryButtons }"
                        @click="logIn"
                        depressed
                      >Entrar</v-btn>
                    </v-card-actions>
                  </v-form>
                </v-window-item>
                <!-- <ForgotPassword ref="form-forgot-password" /> -->
                <v-window-item :value="'forgot-password'">
                  <v-form>
                    <v-card-text>
                      <v-text-field
                        :color="colors.loginScreenFormInputColors"
                        required
                        readonly
                        label="E-mail"
                      ></v-text-field>
                      <span
                        class="caption"
                        :style="{color: colors.loginScreenFormSubtitles}"
                      >Insira o e-mail respectivo</span>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-btn
                        text
                        :style="{ backgroundColor: colors.loginScreenFormSecondaryButtons, color: colors.loginScreenFormSecondaryButtonsText}"
                        @click="step = 'login'"
                      >Voltar</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                        :style="{ color: colors.loginScreenFormPrimaryButtonsText, backgroundColor: colors.loginScreenFormPrimaryButtons }"
                        depressed
                        @click="step = 'register-password'"
                      >Avançar</v-btn>
                    </v-card-actions>
                  </v-form>
                </v-window-item>
                <!-- register password -->
                <v-window-item value="register-password">
                  <v-form>
                    <v-card-text>
                      <v-text-field
                        :color="colors.loginScreenFormInputColors"
                        readonly
                        label="Senha"
                        type="password"
                      ></v-text-field>
                      <v-text-field
                        :color="colors.loginScreenFormInputColors"
                        readonly
                        label="Confirmar senha"
                        type="password"
                      ></v-text-field>
                      <span
                        class="caption"
                        :style="{color: colors.loginScreenFormSubtitles}"
                      >Please enter a password for your account</span>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-btn
                        text
                        :style="{ backgroundColor: colors.loginScreenFormSecondaryButtons, color: colors.loginScreenFormSecondaryButtonsText}"
                        @click="step = 'forgot-password'"
                      >Voltar</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                        @click="step = 'confirmation-code'"
                        :style="{ color: colors.loginScreenFormPrimaryButtonsText, backgroundColor: colors.loginScreenFormPrimaryButtons }"
                        depressed
                      >Avançar</v-btn>
                    </v-card-actions>
                  </v-form>
                </v-window-item>
                <!-- <ConfirmationCode ref="form-confirmation-code" /> -->
                <v-window-item value="confirmation-code">
                  <v-form>
                    <v-card-text>
                      <v-text-field
                        readonly
                        :color="colors.loginScreenFormInputColors"
                        label="Código de confirmação"
                      ></v-text-field>
                      <span
                        class="caption grey--text text--darken-1"
                      >Insira o código de confirmação enviado para o seu e-mail</span>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        @click="step = 'login'"
                        :style="{ color: colors.loginScreenFormPrimaryButtonsText, backgroundColor: colors.loginScreenFormPrimaryButtons }"
                        depressed
                      >Entrar</v-btn>
                    </v-card-actions>
                  </v-form>
                </v-window-item>
              </v-window>
            </v-card>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <div v-if="view === 'project'" id="sandCard">
      <v-btn @click="goBack">Voltar</v-btn>
      <v-content :style="{ background: colors.startScreen }" id="content_panel">
        <v-item-group style="height: 100%">
          <v-container style="height: 100%">
            <v-row>
              <v-col v-if="startScreenPath !== 'common/logo.png'" cols="12">
                <v-img :src="startScreenLogo" width="10%" :style="this.alignStyles"></v-img>
              </v-col>
            </v-row>
            <h2 :style="{ color: colors.startText }" class="title my-6">
              Acesse um dos dashboards disponíveis
              <!-- <router-link :to="{ path: '/home'}">ou volte à seleção de projetos</router-link> -->
            </h2>
            <v-row align="center" justify="center" v-if="project">
              <v-col
                class="card"
                cols="12"
                lg="4"
                v-for="(level, index) in project.levels"
                :key="index"
              >
                <v-hover v-if="project.levels[index].cardBackground" v-slot:default="{ hover }">
                  <v-card
                    @click="selectDashboardPanel(level)"
                    :elevation="hover ? 16 : 2"
                    class="card-img d-flex align-center"
                    style="transition: all 0.35s ease;"
                    :color="hover ? colors.startCardsHover : colors.startCards"
                    :style="{ 'background-image': `url(${level.imgUrl})` }"
                    height="200"
                  >
                    <v-scroll-y-transition>
                      <!-- <v-img :src="level.imgUrl" class="card-img white--text align-center"> -->
                      <div
                        :style="{ color: hover ? colors.startTextCardHover : colors.startTextCard }"
                        class="card-text display-3 flex-grow-1 text-center"
                      >{{ level.name }}</div>
                      <!-- </v-img> -->
                    </v-scroll-y-transition>
                  </v-card>
                </v-hover>
                <v-hover v-else v-slot:default="{ hover }">
                  <v-card
                    @click="selectDashboardPanel(level)"
                    :elevation="hover ? 16 : 2"
                    class="d-flex align-center"
                    style="transition: all 0.35s ease;"
                    :color="hover ? colors.startCardsHover : colors.startCards"
                    height="200"
                  >
                    <v-scroll-y-transition>
                      <!-- <v-img :src="level.imgUrl" class="card-img white--text align-center"> -->
                      <div
                        :style="{ color: hover ? colors.startTextCardHover : colors.startTextCard }"
                        class="display-3 flex-grow-1 text-center"
                      >{{ level.name }}</div>
                      <!-- </v-img> -->
                    </v-scroll-y-transition>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
          </v-container>
        </v-item-group>
      </v-content>
    </div>
    <v-sheet v-if="view === 'dashboard'">
      <v-card class="overflow-hidden">
        <v-navigation-drawer
          :clipped="drawer.clipped"
          :floating="drawer.floating"
          :mini-variant="drawer.mini"
          :permanent="drawer.type === 'permanent'"
          :temporary="drawer.type === 'temporary'"
          :color="colors.menu"
          v-model="drawer.model"
          absolute
          fixed
        >
          <v-img v-if="menuLogoPath !== 'common/logo.png'" :src="this.menuLogo"></v-img>
          <v-treeview
            :items="modelPreview"
            activatable
            open-on-click
            :active.sync="dashboard.active"
            return-object
            @update:active="click(dashboard.active)"
            :color="colors.text"
            id="treeview"
          >
            <template v-slot:label="{item}">
              <v-icon v-if="item.icon" class="mr-3" :color="colors.icons">{{item.icon}}</v-icon>
              <span :style="{ color: colors.text }">{{ item.name }}</span>
            </template>
          </v-treeview>
        </v-navigation-drawer>

        <v-app-bar :clipped-left="drawer.clipped" :color="colors.appBar">
          <v-app-bar-nav-icon @click.stop="drawer.model = !drawer.model">
            <v-icon :color="colors.icons">mdi-menu</v-icon>
          </v-app-bar-nav-icon>
          <v-toolbar-title>{{ dashboard.header }}</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-menu left bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" :color="colors.icons">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
          </v-menu>
        </v-app-bar>

        <v-content fluid id="content_panel">
          <Tableau />
        </v-content>
      </v-card>
    </v-sheet>
  </div>
</template>

<script>
import Tableau from "@/components/client/Tableau";
import Bucket from "@/repositories/bucket";

export default {
  components: {
    Tableau
  },
  data() {
    return {
      rules: {
        cantEmptyRule: [v => !!v || "Campo não pode ficar vazio!"]
      },
      messageError: "Erro",
      step: "login",
      view: "login",
      drawer: {
        model: false,
        type: "temporary",
        clipped: false,
        floating: false,
        mini: false
      },
      home: {
        id: this.$route.params.id
      },
      dashboard: {
        active: [],
        item: 1,
        items: [],
        header: null
      }
    };
  },
  computed: {
    logo() {
      return this.$store.getters.logo;
    },
    colors() {
      return this.$store.state.colors;
    },
    alignStyles() {
      return this.returnAlign();
    },
    menuLogoPath() {
      return this.$store.state.colors.menuLogo;
    },
    menuLogo() {
      return this.menuLogoPath
        ? Bucket.getSignedUrl(this.menuLogoPath, "")
        : Bucket.getSignedUrl("common/logo.png", "");
    },
    startScreenPath() {
      return this.colors.startScreenLogo;
    },
    startScreenLogo() {
      return this.startScreenPath
        ? Bucket.getSignedUrl(this.startScreenPath, "")
        : Bucket.getSignedUrl("common/logo.png", "");
    },
    project() {
      this.$store.state.model.levels.map(el => {
        if (el.cardBackground) {
          let imgRepo = Bucket.getSignedUrl(
            this.$store.state.model.id,
            el.cardBackground.value
          );
          el["imgUrl"] = imgRepo;
        }
      });

      return this.$store.state.model;
    },
    model() {
      return this.$store.state.dashboardPanel;
    },
    modelPreview() {
      if (this.$store.state.dashboardPanel) {
        const home = {
          id: Math.random(),
          icon: "mdi-home",
          type: "home",
          name: "Início"
        };
        const model = [home, ...this.$store.state.dashboardPanel.children];
        return model;
      } else return null;
    },
    steps() {
      return {
        login: {
          title: "Entrar",
          subtitle: ""
        },
        "forgot-password": {
          title: "Recuperar senha",
          subtitle: ""
        },
        "register-email": {
          title: "Inserir E-mail",
          subtitle: "Insira seu e-mail"
        },
        "register-password": {
          title: "Inserir Senha",
          subtitle: "Insira sua senha"
        },
        "confirmation-code": {
          title: "Confirmação",
          subtitle: "Digite o código enviado para o seu e-mail"
        }
      };
    },
    currentWindow(step) {
      return this.steps[step];
    }
  },
  methods: {
    selectDashboardPanel(dashboardPanel) {
      this.$store.commit("setDashboardPanel", dashboardPanel);
      this.view = "dashboard";
    },
    iframe(folder, view) {
  
      this.$store.commit("generateUrl", { folder, view });
    },
    click(active) {
      const item = active[0];
      if (item) {
        if (item.type === "home") {
          this.view = "project";
        } else {
          this.iframe(item.folder, item.view);
          this.header = item.name;
        }
      }
    },
    logIn() {
      this.view = "project";
    },
    returnAlign() {
      if (this.colors.startScreenLogoAlign === 0) {
        return "float: left;";
      } else if (this.colors.startScreenLogoAlign === 1) {
        return "display: block; margin:0 auto;";
      } else if (this.colors.startScreenLogoAlign === 2) {
        return "float: right;";
      }
    },
    goBack() {
      this.view = "login";
    },
    CHANGE_STEP() {}
  },
  watch: {
    modelPreview: {
      handler(value) {
        if (value) {
          const folder = this.modelPreview[this.dashboard.item].folder;
          const view = this.modelPreview[this.dashboard.item].view;
          this.iframe(folder, view);
          this.header = this.modelPreview[this.dashboard.item].name;
        }
      },
      deep: true
    }
  }
};
</script>

<style scoped>
html,
body {
  overflow-y: auto;
}

#treeview {
  min-height: 600px;
}

.card {
  cursor: pointer;
}

.card-img {
  max-width: 100%;
  background-size: cover;
}

.card-img .card-text {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(100, 100, 100, 0.5);
  height: 100%;
}

.card-img .card-text:hover {
  background: rgba(100, 100, 100, 0.1);
}

#sandCard {
  width: 100% !important;
}

#content_panel {
  height: 700px;
  padding: 0 !important;
}

#container {
  /* background-color: black; */
  height: 700px;
}

.expand {
  height: 100%;
  width: 100%;
}

#row {
  height: 100% !important;
}
img {
  max-width: 45%;
}

iframe {
  width: 100%;
  height: 99%;
  border: none;
}
</style>