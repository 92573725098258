<template>
  <v-dialog v-model="show" max-width="800px">
    <v-card>
      <v-card-title>
        Selecione os niveís de acesso
        <v-spacer></v-spacer>
        <v-btn color="success" @click="save">Definir niveís de acesso</v-btn>
      </v-card-title>

      <v-container fluid>
        <v-checkbox
          @change="controlTreeviewSelection(checkBoxTreeView)"
          v-model="checkBoxTreeView"
          class="mx-2"
          label="Projeto completo"
          :disabled="true"
          v-show="false"
        ></v-checkbox>
        <v-treeview
          :selectable="selectable"
          :items="projectStructure"
          root
          selection-type="leaf"
          v-model="selection"
        ></v-treeview>
      </v-container>
      <v-snackbar
        v-model="snackbar"
        :bottom="y === 'bottom'"
        :color="color"
        :left="x === 'left'"
        :multi-line="mode === 'multi-line'"
        :right="x === 'right'"
        :timeout="timeout"
        :top="y === 'top'"
        :vertical="mode === 'vertical'"
      >
        {{ snackBarText }}
        <v-btn dark text @click="snackbar = false">Fechar</v-btn>
      </v-snackbar>
    </v-card>
  </v-dialog>
</template>

<script>
import UserProject from "@/repositories/user-project";
import Projects from "@/repositories/projects";

export default {
  data() {
    return {
      x: null,
      y: "top",
      mode: "",
      timeout: 2000,
      snackBarText: "",
      color: null,
      snackbar: false,
      checkBoxTreeView: false,
      selectable: true,
      selection: [],
      originalStructure: Object,
      customProject: "",
      projectStructure: [],
    };
  },
  props: {
    visible: {
      type: Boolean,
    },
    userId: {
      type: String,
      required: true,
    },
    projectId: {
      type: String,
      required: true,
    },
    role: {
      type: String,
      required: true,
    },
  },
  watch: {
    async show(value) {
      if (value) {
        // console.log(this);
        // UserProject.getByUser(this.userId)
        //   .then((res) => res.json())
        //   .then((res) => {
        //     console.log(res);
        //     console.log("PASSEI AQUI");
        //     console.log(res[0].project_filters);
        //     this.selection = res[0].project_filters;
        //     console.log("SELECIONADO:", this.selection);
        //   })
        //   .catch((err) => console.error(err));
        Projects.get(this.projectId)
          .then((res) => res.json())
          .then((res) => {
            // console.log("PASSEI AQUI TBM");
            // console.log(res); userId
            this.originalStructure = res;
            // console.log("ORIGINAL STRUCTURE: ", this.originalStructure);

            this.projectStructure = res.levels;
            // console.log("PROJECT STRUCT: ", this.projectStructure);

            UserProject.getByUser(this.userId)
              .then((res) => res.json())
              .then((res) => {
                // console.log(res);
                // console.log(res[0].project_filters);
                this.selection = res[0].project_filters;
                // console.log(this.selection);
              })
              .catch((err) => console.error(err));

            // this.selection = res.selectable;
            // console.log("SELECT: ", this.selection);
          })
          .catch((err) => console.error(err));
        // } else {
        //   this.projectStructure.length = 0;
        //   this.selection = [];
        //   this.customProject = "";
      }
    },
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    save() {
      let project_filters = [];
      if (!this.checkBoxTreeView) {
        project_filters = [...this.selection];
      }
      this.$emit("projectFilters", project_filters);
      const userProject = {
        project_filters,
        access_policy: this.role,
      };

      this.snackBarText =
        "Níveis de acesso selecionados. Clique em Salvar Alterações para confirmar.";
      this.snackbar = true;
      // this.color = "success";
    },
    controlTreeviewSelection(checkBoxTreeView) {
      this.selectable = !checkBoxTreeView;
    },
  },
  mounted() {
    // console.log("USERID", this.userId);
  },
};
</script>