var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"green","outlined":"","to":{ path: 'create' },"append":""}},[_vm._v("Novo projeto")])],1)]},proxy:true},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("active")(item.active)))]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("simplifyUuid")(item.id)))]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("localeDate")(item.created_at)))]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("localeDate")(item.updated_at)))]}},{key:"item.url",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":_vm._f("path")(item.domain),"target":"_blank"}},[_vm._v(_vm._s(_vm._f("path")(item.domain)))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"outlined":"","small":"","to":{ path: ("/project/" + (item.id)) }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-monitor-dashboard")])],1),_c('v-btn',{staticClass:"mr-2",attrs:{"outlined":"","color":"blue","dark":"","small":"","to":{ path: ((item.id) + "/update") },"append":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1),_c('v-btn',{staticClass:"mr-2",attrs:{"outlined":"","color":"red","dark":"","small":""},on:{"click":function($event){return _vm.deleteScreen(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.fetch}},[_vm._v("Reset")])]},proxy:true}])}),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Deletar projeto")])]),_c('v-card-text',[_c('v-container',[_c('p',[_vm._v("Você tem certeza de que deseja deletar este projeto?")])])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.deleteDialogSave(_vm.item)}}},[_vm._v("Sim")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteDialogClose}},[_vm._v("Cancelar")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }