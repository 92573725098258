<template>
  <v-window-item value="forgot-password">
    <v-form :value="formValid">
      <v-card-text>
        <v-text-field
          required
          label="E-mail"
          :color="colors.loginScreenFormInputColors"
          :value="form.email"
          :rules="[cantEmptyRule]"
          @input="e => CHANGE_FORM({input: 'email', value: e})"
        ></v-text-field>
        <span
          class="caption"
          :style="{color: colors.loginScreenFormSubtitles}"
        >Insira o e-mail respectivo <b> ( Somente e-mails já confirmados com acesso ao Sistema )</b></span>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          text
          @click="[CHANGE_STEP('login')]"
          :style="{ backgroundColor: colors.loginScreenFormSecondaryButtons, color: colors.loginScreenFormSecondaryButtonsText}"
        >Voltar</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          depressed
          @click="FORGOT_PASSWORD_AUTHORIZATION()"
          :style="{ color: colors.loginScreenFormPrimaryButtonsText, backgroundColor: colors.loginScreenFormPrimaryButtons }"
        >Avançar</v-btn>
      </v-card-actions>
    </v-form>
  </v-window-item>
</template>

<script>
import { mapMutations, mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "ForgotPassword",
  computed: {
    ...mapState("Cognito", ["form", "formValid"]),
    ...mapGetters("Cognito", ["cantEmptyRule"]),
    colors() {
      return this.$store.state.colors;
    }
  },
  methods: {
    ...mapMutations("Cognito", [
      "CHANGE_FORM",
      "CHANGE_STEP",
      "CHANGE_FORM_VALID"
    ]),
    ...mapActions("Cognito", ["REGISTER_USER", "FORGOT_PASSWORD_AUTHORIZATION"])
  }
};
</script>

<style>
</style>