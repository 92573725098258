<template>
  <v-row id="row" justify="center" align="center">
    <!-- <img alt="Logo" :src="logo" /> -->
    <img alt="Logo" :src="logoPath" />

  </v-row>
</template>

<script>
import s3 from "@/repositories/bucket";
import logoPadrao from '@/icon-512x512.png';

export default {
  name: "Background",
  computed: {
    path() {
      return this.$store.state.colors.loginScreenBackgroundImg;
    },
    logo() {
      return this.path ? s3.getSignedUrl(this.path, "") :  s3.getSignedUrl("common/logo.png", "");
    }
  },
  data() {
    return {
      logoPath: logoPadrao,
    };
  },
};
</script>

<style>
#row {
  height: 100%;
}
img {
  max-width: 300px;
}
</style>