import Vue from 'vue';
import Vuetify, {
    VLayout
  } from 'vuetify/lib'

Vue.use(Vuetify, {
    iconfont: ['md', 'fa'],
    components: {
      VLayout
    }
  })

export default new Vuetify({
});
