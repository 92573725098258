<template>
  <v-container id="container" fluid>
    <v-row id="row" :class="{ expand : $vuetify.breakpoint.mdAndUp }">
      <v-col
        :class="{ expand : $vuetify.breakpoint.mdAndUp }"
        :cols="$vuetify.breakpoint.mdAndUp ? 7 : 12"
      >
        <Background />
      </v-col>
      <v-col
        :class="{ 'px-6': $vuetify.breakpoint.mdAndDown }"
        :cols="$vuetify.breakpoint.mdAndUp ? 5 : 12"
      >
        <LoginForm />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Background from "@/components/auth/Background.vue";
import LoginForm from "@/components/auth/LoginForm.vue";

export default {
  name: "Login",
  components: {
    Background,
    LoginForm
  },
  beforeMount() {
    this.$vuetify.theme.dark = false;
    this.$store.commit("defaultColors")
  }
};
</script>

<style>
#container {
  background-color: black;
  height: 100%;
}

.expand {
  height: 100%;
  width: 100%;
}
</style>