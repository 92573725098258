<template>
  <v-window-item value="new-account-password">
    <v-form :value="formValid">
      <v-card-text>
        <v-text-field
          required
          label="Senha"
          type="password"
          :rules="[cantEmptyRule, passwordValidRule]"
          :value="form.password"
          @input="e => CHANGE_FORM({input: 'password', value: e})"
        ></v-text-field>
        <v-text-field
          required
          :rules="[cantEmptyRule, passwordValidRule, passwordConfirmationRule]"
          label="Confirmar senha"
          type="password"
          :value="form.passwordConfirm"
          @input="e => CHANGE_FORM({input: 'passwordConfirm', value: e})"
        ></v-text-field>
        <span class="caption grey--text text--darken-1">Por favor, insira uma senha para sua conta</span>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn text @click="CHANGE_STEP('login')">Voltar</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          @click="(formValid && !forgotSteps) ? REGISTER_USER() : NEW_PASSWORD_REQUIRED()"
          :loading="loader"
          :disabled="!formValid || loader"
          color="secondary"
          depressed
        >Concluir</v-btn>
      </v-card-actions>
    </v-form>
  </v-window-item>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";

export default {
  name: "NewAccountPassword",
  computed: {
    ...mapState("Cognito", ["form", "formValid", "loader", "forgotSteps"]),
    ...mapGetters("Cognito", [
      "cantEmptyRule",
      "passwordValidRule",
      "passwordConfirmationRule"
    ])
  },
  methods: {
    ...mapMutations("Cognito", [
      "CHANGE_FORM",
      "CHANGE_STEP",
      "CHANGE_FORM_VALID"
    ]),
    ...mapActions("Cognito", ["REGISTER_USER", "NEW_PASSWORD_REQUIRED"])
  }
};
</script>

<style>
</style>