<template>
  <v-container v-if="loading">
    <v-row align="center" justify="center">
      <v-col cols="auto">
        <v-icon x-large>mdi-loading mdi-spin</v-icon>
      </v-col>
    </v-row>
  </v-container>
  <iframe v-else :src="src" frameborder="0" />
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState("Cognito", ["client", "userAuthenticated"]),
    src() {
      return this.$store.state.trustedUrl;
    },
    loading() {
      return !this.src;
    }
  },
  beforeDestroy() {
    this.$store.state.trustedUrl = null;
  },
  mounted() {
    console.log("tst tableu.vue", this.userAuthenticated);
  }
};
</script>

<style>
#sandbox {
  width: 100% !important;
}

html,
body {
  overflow-y: auto;
}

#content_panel {
  padding: 0 !important;
}

iframe {
  width: 100%;
  height: 100%;
  min-height: 700px;
  border: none;
}
</style>