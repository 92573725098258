<template>
  <v-window-item value="register-email">
    <v-form :value="formValid" >
      <v-card-text>
        <v-text-field
          required
          :rules="[cantEmptyRule, emailValidRule]"
          label="E-mail"
          :value="form.email"
          @input="e => CHANGE_FORM({input: 'email', value: e})"
        ></v-text-field>
        <span class="caption grey--text text--darken-1">Por favor entrar com seu email</span>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn text @click="CHANGE_STEP('login')">Voltar</v-btn>
        <v-spacer></v-spacer>
        <v-btn :disabled="!formValid" color="secondary" depressed @click="CHANGE_STEP('register-password')">Avançar</v-btn>
      </v-card-actions>
    </v-form>
  </v-window-item>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";

export default {
  name: "RegisterEmail",
  computed: {
    ...mapState("Cognito",["form", "formValid"]),
    ...mapGetters("Cognito",["cantEmptyRule", "emailValidRule"])
  },
  methods: {
    ...mapMutations("Cognito",["CHANGE_FORM", "CHANGE_STEP", "CHANGE_FORM_VALID"])
  },
};
</script>

<style>
</style>