<template>
  <v-window-item value="register-password">
    <v-form :value="formValid">
      <v-card-text>
        <v-text-field
          required
          label="Senha"
          type="password"
          :color="colors.loginScreenFormInputColors"
          :rules="[cantEmptyRule, passwordValidRule]"
          :value="form.password"
          @input="e => CHANGE_FORM({input: 'password', value: e})"
        ></v-text-field>
        <v-text-field
          required
          :rules="[cantEmptyRule, passwordValidRule, passwordConfirmationRule]"
          :color="colors.loginScreenFormInputColors"
          label="Confirme a senha"
          type="password"
          :value="form.passwordConfirm"
          @input="e => CHANGE_FORM({input: 'passwordConfirm', value: e})"
        ></v-text-field>
        <span
          class="caption"
          :style="{color: colors.loginScreenFormSubtitles}"
        >Por favor, insira uma senha para sua conta</span>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          text
          @click="CHANGE_STEP(forgotSteps ? 'forgot-password' : 'register-email')"
          :style="{ backgroundColor: colors.loginScreenFormSecondaryButtons, color: colors.loginScreenFormSecondaryButtonsText}"
        >Voltar</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          @click="(formValid && !forgotSteps) ? REGISTER_USER() : FORGOT_PASSWORD_AUTHORIZATION()"
          :loading="loader"
          :disabled="!formValid || loader"
          :style="{ color: colors.loginScreenFormPrimaryButtonsText, backgroundColor: colors.loginScreenFormPrimaryButtons }"
          depressed
        >Avançar</v-btn>
      </v-card-actions>
    </v-form>
  </v-window-item>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";

export default {
  name: "RegisterPassword",
  computed: {
    ...mapState("Cognito", ["form", "formValid", "loader", "forgotSteps"]),
    ...mapGetters("Cognito", [
      "cantEmptyRule",
      "passwordValidRule",
      "passwordConfirmationRule"
    ]),
    colors() {
      return this.$store.state.colors;
    }
  },
  methods: {
    ...mapMutations("Cognito", [
      "CHANGE_FORM",
      "CHANGE_STEP",
      "CHANGE_FORM_VALID"
    ]),
    ...mapActions("Cognito", ["REGISTER_USER", "FORGOT_PASSWORD_AUTHORIZATION"])
  }
};
</script>

<style>
</style>