<template>
  <v-container v-if="loading">
    <v-row align="center" justify="center">
      <v-col cols="auto">
        <v-icon x-large>mdi-loading mdi-spin</v-icon>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else fluid>
    <ModalEditProject
      :visible="showModal"
      :projectId="projectIdModal"
      :userId="userIdModal"
      :role="role"
      v-on:projectFilters="saveFilters"
      @close="showModal = false"
    />
    <v-row dense>
      <v-col>
        <v-card>
          <v-card-title>Atualizar</v-card-title>
          <v-container fluid>
            <v-row>
              <v-col v-if="role === 'god'">
                <v-checkbox
                  color="primary"
                  v-model="project.god"
                  label="Administrador geral"
                ></v-checkbox>
              </v-col>
              <v-col>
                <v-combobox
                  :disabled="project.god"
                  v-model="project.project"
                  :items="projects"
                  label="Projeto"
                ></v-combobox>
              </v-col>
              <v-col>
                <v-combobox
                  :disabled="project.god"
                  v-model="project.role"
                  :items="roles"
                  label="Função"
                ></v-combobox>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-btn color="primary" text :to="{ path: '/admin/users' }" exact
              >Voltar</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              :disabled="isDisabled"
              @click="callModal"
              >Definir níveis de acesso</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="save">Salvar alterações</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Cognito from "@/repositories/cognito-user";
import UserProject from "@/repositories/user-project";
import Projects from "@/repositories/projects";
import ModalEditProject from "./ModalEditProject.vue";
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      showModal: false,
      loading: true,
      user: null,
      projectIdModal: "",
      userIdModal: "",
      filtersProject: [],
      projects: [],
      roles: [
        {
          text: "Básico",
          value: "viewer",
        },
        {
          text: "Administrador do projeto",
          value: "admin",
        },
      ],
      project: {
        god: null,
        project: null,
        role: null,
      },
      role: null,
    };
  },
  components: {
    ModalEditProject,
  },
  computed: {
    ...mapState("Cognito", ["client", "userAuthenticated"]),
    attributes() {
      return this.user ? this.user.UserAttributes : null;
    },
    userProfile() {
      return this.user // eslint-disable-next-line no-unused-vars
        ? (({ UserAttributes, ...data }) => data)(this.user)
        : null;
    },
    isDisabled() {
      if (this.project.project && this.project.role) {
        if (this.project.project && this.project.role.value === "viewer") {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
  },
  watch: {
    project: {
      handler(value) {
        if (value.god === true) {
          value.project = null;
          value.role = null;
        }
      },
      deep: true,
    },
    user(value) {
      if (value) {
        UserProject.getByUser(this.user.Username)
          .then((res) => res.text())
          .then(() => {});
      }
    },
    async userAuthenticated(user) {
      if (user) {
        this.loading = true;

        const groups = await Cognito.listGroupsForUser(
          user.username,
          user.signInUserSession.idToken.jwtToken
        )
          .then((res) => res.json())
          .then((res) => res.Groups);

        let result = "";

        groups.forEach((group) => {
          if (result === "god") return;
          if (result === "admin") return;
          result = group.GroupName;
        });

        result = result === "" ? "viewer" : result;

        this.role = result;

        const id = this.$route.params.id;
        // recupera os dados do usuário
        Cognito.get(id)
          .then((res) => res.text())
          .then((res) => {
            this.user = JSON.parse(res);
          })
          .catch((err) => console.error(err));

        // recupera os dados dos projetos existentes
        Projects.getAll()
          .then((res) => res.text())
          .then((res) => {
            this.projects = JSON.parse(res).map((project) => {
              return { text: project.name, value: project.id };
            });

            // recupera o projeto já atribuídos a esse usuário
            UserProject.getByUser(id)
              .then((res) => res.text())
              .then((res) => {
                const response = JSON.parse(res)[0];
                if (response.project_id === "god") {
                  this.project.god = true;
                } else {
                  this.roles.forEach((role) => {
                    if (role.value === response.access_policy) {
                      this.project.role = role;
                    }
                  });
                  this.projects.forEach((project) => {
                    if (project.value === response.project_id) {
                      this.project.project = project;
                    }
                  });
                  this.project.project;
                }
                this.loading = false;
              })
              .catch((err) => console.error(err));
          })
          .catch((err) => console.error(err));
      }
    },
  },
  methods: {
    ...mapActions("Cognito", ["CURRENT_AUTHENTICATED_USER"]),
    save() {
      let project;
      let projectsUnmarked;

      if (this.project.god === true) {
        project = {
          user_id: this.user.Username,
          project_id: "god",
          access_policy: "god",
        };
        projectsUnmarked = this.projects.map((project) => {
          return { user_id: this.user.Username, project_id: project.value };
        });
      } else {
        project = {
          user_id: this.user.Username,
          project_id: this.project.project.value,
          access_policy: this.project.role.value,
          project_filters: this.filtersProject,
        };
        projectsUnmarked = this.projects
          .filter((project) => {
            return project.value !== this.project.project.value;
          })
          .map((project) => {
            return { user_id: this.user.Username, project_id: project.value };
          });
        projectsUnmarked.push({
          user_id: this.user.Username,
          project_id: "god",
        });
      }

      UserProject.create(project)
        .then((res) => res.text())
        .catch((err) => console.error(err));

      projectsUnmarked.forEach((userProject) => {
        UserProject.delete(userProject)
          .then((res) => res.text())
          .then(() => {})
          .catch((err) => console.error(err));
      });

      this.$router.push({ path: "/admin/users" });
    },
    callModal() {
      this.userIdModal = this.user.Username;
      this.projectIdModal = this.project.project.value;
      this.role = this.project.role.value;
      this.showModal = true;
    },
    saveFilters(value) {
      this.filtersProject = value;
    },
  },
  created() {
    this.CURRENT_AUTHENTICATED_USER();
  },
};
</script>