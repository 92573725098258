<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>Cadastrar usuário</v-card-title>
          <v-row>
            <v-col cols="6">
              <v-card-text>
                <v-text-field
                  v-model="newUser.email"
                  label="E-mail"
                ></v-text-field>
              </v-card-text>
            </v-col>
            <v-col v-if="role === 'god'">
              <v-card-text>
                <v-checkbox
                  color="primary"
                  v-model="newUser.god"
                  label="Administrador geral"
                ></v-checkbox>
              </v-card-text>
            </v-col>
            <v-col>
              <v-card-text>
                <v-combobox
                  :disabled="newUser.god"
                  v-model="newUser.project"
                  :items="groups"
                  label="Projeto"
                ></v-combobox>
              </v-card-text>
            </v-col>
            <v-col>
              <v-card-text>
                <v-combobox
                  :disabled="newUser.god"
                  v-model="newUser.role"
                  :items="roles"
                  label="Função"
                ></v-combobox>
              </v-card-text>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :loading="loading"
              flat
              class="mx-2"
              color="success"
              @click="createUser()"
            >
              <v-icon left>mdi-account-plus</v-icon>
              Adicionar usuário
            </v-btn>
            <v-btn
              :loading="loading"
              class="mx-2"
              color="primary"
              @click="userBatchDialog = true"
            >
              <v-icon left>mdi-account-multiple-plus</v-icon>
              Adicionar em Lote
            </v-btn>
            <v-btn
              :loading="loading"
              class="mx-2"
              color="primary"
              @click="userUpdateBatchDialog = true"
            >
              <v-icon left>mdi-account-edit</v-icon>
              Editar em lote
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Usuários
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquisar"
              single-line
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :search="search"
            :headers="headers"
            :items="items"
            :loading="loading"
            class="elevation-1"
          >
            <template v-slot:item.Enabled="{ item }">
              <v-tooltip top :open-delay="500">
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <v-switch
                      color="success"
                      v-model="item.Enabled"
                      @change="enableDisableUser(item)"
                      >{{ item.Enabled }}</v-switch
                    >
                  </span>
                </template>
                <span>{{ item.Enabled ? "Habilitar" : "Desabilitar" }}</span>
              </v-tooltip>
            </template>
            <template v-slot:item.Username="{ item }">{{
              item.Username | simplifyUuid
            }}</template>
            <template v-slot:item.UserCreateDate="{ item }">{{
              item.UserCreateDate | localeDate
            }}</template>
            <template v-slot:item.Count="{ item }">{{
              counter && counter[item.Username]
                ? counter[item.Username].Count
                : ""
            }}</template>
            <template v-slot:item.LastAccess="{ item }">
              <span>{{
                (counter && counter[item.Username]
                  ? counter[item.Username].LastAccess
                  : "") | localeDate
              }}</span>
            </template>
            <!-- <template v-slot:item.LastAccess="{ item }">
              <span>{{ item.LastAccess | localeDate }} </span>
            </template> -->
            <template v-slot:item.UserLastModifiedDate="{ item }">{{
              item.UserLastModifiedDate | localeDate
            }}</template>
            <!-- <template v-slot:item.Groups="{ item }" v-if="assignedGroups">
              <v-select
                v-model="assignedGroups[item.Username]"
                :items="groups"
                label="Projetos"
                chips
                multiple
              ></v-select>
            </template>-->
            <template v-slot:item.action="{ item }">
              <v-tooltip top :open-delay="500">
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <v-btn
                      outlined
                      class="mr-2"
                      color="info"
                      dark
                      small
                      exact
                      @click="resetUserPasswordDialog(item)"
                    >
                      <v-icon small>mdi-key</v-icon>
                    </v-btn>
                  </span>
                </template>
                <span>Resetar senha</span>
              </v-tooltip>
              <v-tooltip top :open-delay="500">
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <v-btn
                      outlined
                      class="mr-2"
                      color="info"
                      dark
                      small
                      exact
                      :to="{ path: `users/${item.Username}/update` }"
                    >
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                  </span>
                </template>
                <span>Editar</span>
              </v-tooltip>
              <v-tooltip top :open-delay="500">
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <v-btn
                      outlined
                      class="mr-2"
                      color="red"
                      dark
                      small
                      exact
                      @click="deleteDialog(item)"
                    >
                      <v-icon small>mdi-delete</v-icon>
                    </v-btn>
                  </span>
                </template>
                <span>Excluir</span>
              </v-tooltip>
            </template>
            <template v-slot:no-data>
              <v-btn color="primary" @click="fetch">Reset</v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar.active"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}
      <v-btn text outlined @click="snackbar.active = false">Fechar</v-btn>
    </v-snackbar>
    <v-dialog v-model="dialog.active" max-width="700">
      <v-card :color="dialog.color">
        <v-card-title class="title">Confirmar exclusão?</v-card-title>
        <v-card-text v-if="dialog.user"
          >Está prestes a excluir {{ dialog.user.Email }} e essa ação é
          irreversível</v-card-text
        >
        <v-card-actions>
          <v-btn color="info" text @click="dialog.active = false">Não</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="deleteUser(dialog.user)">Sim</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="resetDialog.active" max-width="700">
      <v-card :color="resetDialog.color">
        <v-card-title class="title">Confirmar reset?</v-card-title>
        <v-card-text v-if="resetDialog.user"
          >Está prestes a resetar a senha de {{ resetDialog.user.Email }} e essa
          ação é irreversível</v-card-text
        >
        <v-card-actions>
          <v-btn color="info" text @click="resetDialog.active = false"
            >Não</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="resetUserPassword(resetDialog.user)"
            >Sim</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="userBatchDialog" max-width="800">
      <v-card flat tile class="mx-auto" max-width="800">
        <v-card-title class="title font-weight-regular justify-space-between">
          Inclusões em lote
        </v-card-title>
        <v-card-subtitle v-if="userBatchWindowStep === 1"
          >Selecione um projeto</v-card-subtitle
        >
        <v-card-subtitle v-if="userBatchWindowStep === 2"
          >Cole os e-mails delimitados por vírgula, sem
          espaços.</v-card-subtitle
        >
        <v-card-subtitle v-if="userBatchWindowStep === 3"
          >Cole os ID's dos botões delimitados por vírgula, sem
          espaços.</v-card-subtitle
        >

        <!-- <v-card-subtitle v-if="userBatchWindowStep === 3"
          >Selecione os botões.</v-card-subtitle
        > -->

        <v-container fluid>
          <v-window v-model="userBatchWindowStep">
            <v-window-item :value="1">
              <v-combobox
                v-model="newUser.project"
                :items="groups"
                label="Projeto"
              ></v-combobox>
            </v-window-item>

            <v-window-item :value="2">
              <v-textarea
                v-model="userBatchEmailList"
                auto-grow
                shaped
                rows="4"
              >
              </v-textarea>
            </v-window-item>
            <v-window-item :value="3">
              <v-textarea
                v-model="userBatchButtonList"
                auto-grow
                shaped
                rows="4"
              >
              </v-textarea>
              <!-- <v-treeview
                v-model="userBatchButtonList"
                :selectable="selectable"
                :items="botoes"
                root
                selection-type="leaf"
              ></v-treeview> -->
            </v-window-item>
          </v-window>
        </v-container>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            :disabled="userBatchWindowStep === 1"
            @click="userBatchWindowStep--"
            >Voltar</v-btn
          >
          <v-btn
            color="primary"
            v-if="userBatchWindowStep === 1 || userBatchWindowStep === 2"
            @click="userBatchWindowStep++"
            >Avançar</v-btn
          >
          <v-btn
            color="success"
            v-if="userBatchWindowStep === 3"
            @click="
              startUsersBatch(newUser, userBatchEmailList, userBatchButtonList)
            "
            >Criar usuários</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="userUpdateBatchDialog" max-width="800">
      <v-card flat tile class="mx-auto" max-width="800">
        <v-card-title class="title font-weight-regular justify-space-between">
          Edição em lote
        </v-card-title>
        <v-card-subtitle v-if="userBatchWindowStep === 1"
          >Selecione um projeto</v-card-subtitle
        >
        <v-card-subtitle v-if="userBatchWindowStep === 2"
          >Cole os e-mails delimitados por vírgula, sem
          espaços.</v-card-subtitle
        >
        <v-card-subtitle v-if="userBatchWindowStep === 3"
          >Cole os ID's dos botões delimitados por vírgula, sem
          espaços.</v-card-subtitle
        >

        <!-- <v-card-subtitle v-if="userBatchWindowStep === 3"
          >Selecione os botões.</v-card-subtitle
        >        -->

        <v-container fluid>
          <v-window v-model="userBatchWindowStep">
            <v-window-item :value="1">
              <v-combobox
                v-model="editUser.project"
                :items="groups"
                label="Projeto"
              ></v-combobox>
            </v-window-item>

            <v-window-item :value="2">
              <v-textarea
                v-model="userBatchEmailList"
                auto-grow
                shaped
                rows="4"
              >
              </v-textarea>
            </v-window-item>
            <v-window-item :value="3">
              <v-textarea
                v-model="userBatchButtonList"
                auto-grow
                shaped
                rows="4"
              >
              </v-textarea>
              <!-- <v-treeview
                v-model="userBatchButtonList"
                :selectable="selectable"
                :items="botoesedicao"
                root
                selection-type="leaf"
              ></v-treeview> -->
            </v-window-item>
          </v-window>
        </v-container>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            :disabled="userBatchWindowStep === 1"
            @click="userBatchWindowStep--"
            >Voltar</v-btn
          >
          <v-btn
            color="primary"
            v-if="userBatchWindowStep === 1 || userBatchWindowStep === 2"
            @click="userBatchWindowStep++"
            >Avançar</v-btn
          >
          <v-btn
            color="success"
            v-if="userBatchWindowStep === 3"
            @click="
              updateUsersBatch(
                editUser,
                userBatchEmailList,
                userBatchButtonList
              )
            "
            >Editar usuários</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Download v-if="counter !== null" :items="items" :counter="counter" />
  </div>
</template>

<script>
import Cognito from "@/repositories/cognito-user";
import UserProject from "@/repositories/user-project";
import Counter from "@/repositories/counter";
import { mapState, mapActions } from "vuex";
import Download from "./Download";
import Projects from "@/repositories/projects";

export default {
  components: {
    Download,
  },
  data() {
    return {
      botoes: [],
      botoesedicao: [],
      selectable: true,
      // buttonsRepo: null,
      userBatchEmailList: "",
      //userBatchButtonList: [],
      userBatchButtonList: "",
      userBatchWindowStep: 1,
      userBatchDialog: false,
      userUpdateBatchDialog: false,
      search: "",
      counter: null,
      items: [],
      groups: [],
      assignedGroups: null,
      newUser: {
        email: null,
        god: null,
        project: null,
        role: null,
      },
      editUser: {
        email: null,
        god: null,
        project: null,
        role: null,
      },
      roles: [
        {
          text: "Básico",
          value: "viewer",
        },
        {
          text: "Administrador do projeto",
          value: "admin",
        },
      ],
      snackbar: {
        active: false,
        text: "",
        color: "",
        timeout: 4000,
      },
      dialog: {
        active: false,
        text: "",
        color: "",
      },
      resetDialog: {
        active: false,
        text: "",
        color: "",
      },
      headers: [
        { text: "Habilitado", value: "Enabled" },
        { text: "E-mail", value: "Email" },
        { text: "Id", value: "Username" },
        // { text: "Projetos atribuídos", value: "Groups", sortable: false },
        { text: "Número de acessos", value: "Count" },
        { text: "Data do último acesso", value: "LastAccess" },
        { text: "Criação", value: "UserCreateDate" },
        { text: "Última modificação", value: "UserLastModifiedDate" },
        { text: "Estado da conta", value: "UserStatus", sortable: false },
        { text: "", value: "action", sortable: false },
      ],
      loading: false,
      role: null,
    };
  },
  computed: {
    ...mapState("Cognito", ["client", "userAuthenticated", "userGroups"]),
  },
  methods: {
    ...mapActions("Cognito", ["CURRENT_AUTHENTICATED_USER"]),
    activateSnackbar(text, color, timeout) {
      this.snackbar = { ...this.message, active: true, text, color, timeout };
    },
    activateDeleteDialog(text, user) {
      this.dialog = { ...this.message, active: true, text, user };
    },
    activateResetPasswordDialog(text, user) {
      this.resetDialog = { ...this.message, active: true, text, user };
    },

    updateUsersBatch(editUser, userBatchEmailList, userBatchButtonList) {
      this.loading = true;
      const batchEditProject = editUser.project.value.id;
      const userEditEmailList = userBatchEmailList.toLowerCase().split(",");
      //const userEditButtonList = userBatchButtonList;
      const userEditButtonList = userBatchButtonList.split(",");
      // console.clear();
      // console.log("batchEditProject", batchEditProject);
      // console.log("userEditEmailList", userEditEmailList);
      // console.log("userEditButtonList", userEditButtonList);
      // console.log(batchEditProject, userEditEmailList, userEditButtonList)

      var promEditHolder = new Promise((resolve, reject) => {
        userEditEmailList.forEach((item, index, array) => {
          Cognito.get(item)
            .then((res) => res.text())
            .then((res) => {
              const response = JSON.parse(res);
              if (response.error || response.statusCode) {
                this.activateSnackbar(
                  response.error || response.message,
                  "error"
                );
                this.loading = false;
              } else {
                // console.log(response)
                let projectEditUser = {
                  user_id: response.Username,
                  project_id: batchEditProject,
                  access_policy: "viewer",
                  project_filters: userEditButtonList,
                };
                // console.log("INDEX:",index)
                // // console.log("Email:",item)
                // console.log("Id do usuario:",response.Username)
                // console.log("\nId do projeto:",batchEditProject)
                // console.log("\nId dos botoes:",userEditButtonList)

                UserProject.create(projectEditUser)
                  .then((res) => {
                    res.json();
                    // console.log("PASSOU AQUI!!!!!", res)
                  })
                  .then(() => {
                    this.editUser = {
                      email: null,
                      god: null,
                      project: null,
                      role: null,
                    };
                    this.userBatchEmailList = "";
                    this.userBatchButtonList = "";
                  });
              }
            });
          if (index === array.length - 1) resolve();
        });
      }).finally(() => {
        this.loading = false;
        this.userUpdateBatchDialog = false;
        this.fetch();
        console.log("allDone");
      });
    },

    startUsersBatch(newUser, userBatchEmailList, userBatchButtonList) {
      this.loading = true;
      const batchProject = newUser.project.value.id;
      const userEmailList = userBatchEmailList.split(",");
      //const userButtonList = userBatchButtonList;
      const userButtonList = userBatchButtonList.split(",");
      // const buttonsObject = userButtonList.map((value) => {
      //   return { S: value };
      // });
      // const finalButtonsObject = {
      //   L: buttonsObject,
      // };
      // this.buttonsRepo = finalButtonsObject

      // console.log("PROJECT", batchProject);
      // console.log("EMAIL LIST", userEmailList);
      // console.log("BUTTONS OBJECT", finalButtonsObject);

      // console.log("Evento batch funciona!!!");
      // console.log("testando projeto do usuário", batchProject);
      // console.log("Lista de e-mails", userEmailList);
      var promHolder = new Promise((resolve, reject) => {
        userEmailList.forEach((item, index, array) => {
          const user = {
            email: item,
          };
          Cognito.createUser(user)
            .then((res) => res.text())
            .then((res) => {
              const response = JSON.parse(res);
              if (response.error || response.statusCode) {
                this.activateSnackbar(
                  response.error || response.message,
                  "error"
                );
                this.loading = false;
              } else {
                const createdUser = {
                  user_id: response.User.Username,
                  project_id: batchProject,
                  access_policy: "viewer",
                  project_filters: userButtonList,
                };
                UserProject.create(createdUser)
                  .then((res) => res.json())
                  .then(() => {
                    this.newUser = {
                      email: null,
                      god: null,
                      project: null,
                      role: null,
                    };
                    this.userBatchEmailList = "";
                    this.userBatchButtonList = "";
                  });
              }
            });
          if (index === array.length - 1) resolve();
        });
        // userEmailList.forEach((item, index, array) => {
        //   console.log(item);
        //   if (index === array.length - 1) resolve();
        // });
      });

      promHolder.then(() => {
        this.loading = false;
        this.userBatchDialog = false;
        this.fetch();
        console.log("allDone");
      });
    },
    createUser() {
      if (this.newUser.email === null) {
        this.activateSnackbar("Usuário deve ter um e-mail", "error");
        return;
      } else if (this.newUser.god !== true) {
        if (this.newUser.project === null) {
          this.activateSnackbar("Usuário deve ter um projeto", "error");
          return;
        } else if (this.newUser.role === null) {
          this.activateSnackbar("Usuário deve ter um papel", "error");
          return;
        }
      }
      this.loading = true;

      const project = this.newUser.god ? "god" : this.newUser.project.value.id;
      const role = this.newUser.god ? "god" : this.newUser.role.value;
      const user = {
        email: this.newUser.email,
      };
      Cognito.createUser(user)
        .then((res) => res.text())
        .then((res) => {
          const response = JSON.parse(res);
          if (response.error || response.statusCode) {
            this.activateSnackbar(response.error || response.message, "error");
            this.loading = false;
          } else {
            this.loading = false;
            this.email = "";
            this.activateSnackbar(
              `${user.email} criado com sucesso`,
              "success"
            );
            const createdUser = {
              user_id: response.User.Username,
              project_id: project,
              access_policy: role,
            };
            UserProject.create(createdUser)
              .then((res) => res.json())
              .then(() => {
                this.newUser = {
                  email: null,
                  god: null,
                  project: null,
                  role: null,
                };
                this.fetch();
              });
          }
        });
    },
    enableDisableUser(user) {
      if (user.Enabled) {
        Cognito.enableUser(user)
          .then((res) => res.text())
          .then((res) => {
            const response = JSON.parse(res);
            if (response.message) {
              this.activateSnackbar(response.message, "error");
            } else {
              this.activateSnackbar(
                `${user.Email} habilitado com sucesso`,
                "success"
              );
            }
          });
      } else {
        Cognito.disableUser(user)
          .then((res) => res.text())
          .then((res) => {
            const response = JSON.parse(res);
            if (response.message) {
              this.activateSnackbar(response.message, "error");
            } else {
              this.activateSnackbar(
                `${user.Email} desabilitado com sucesso`,
                "success"
              );
            }
          });
      }
    },
    deleteDialog(user) {
      this.activateDeleteDialog(`Confirma a exclusão de ${user.Email}?`, user);
    },
    resetUserPasswordDialog(user) {
      this.activateResetPasswordDialog(
        `Confirma o reset da senha de ${user.Email}?`,
        user
      );
    },
    deleteUser(user) {
      this.dialog.active = false;
      Cognito.deleteUser(user)
        .then((res) => res.text())
        .then((res) => {
          const response = JSON.parse(res);
          if (response.message) {
            this.activateSnackbar(response.message, "error");
          } else {
            this.activateSnackbar(
              `${user.Email} excluído com sucesso`,
              "success"
            );
          }
          this.fetch();
        });
    },
    resetUserPassword(user) {
      this.resetDialog.active = false;
      Cognito.resetUserPassword(user)
        .then((res) => res.text())
        .then((res) => {
          const response = JSON.parse(res);
          if (response.message) {
            this.activateSnackbar(response.message, "error");
          } else {
            this.activateSnackbar(
              `Senha de ${user.Email} resetada com sucesso`,
              "success"
            );
          }
          this.fetch();
        });
    },
    listGroups() {
      this.loading = true;

      // const teste = this.fetchProjetos();
      // console.log("TESTE DO PROJETO:", this.fetchProjetos());

      Projects.getAll()
        .then((res) => res.text())
        .then((res) => {
          // console.clear();
          // console.log("PROJETOS DE TESTE:", res);
          this.itemsRand = JSON.parse(res);
          // console.log("PROJETOS DO THIS ITEMS RAND:", this.itemsRand);

          this.groups = [];
          JSON.parse(res).forEach((group) => {
            // console.log("group:", group);
            // console.log("group.Description:", group.name);
            // console.log("group.GroupName:", group.id);
            if (group.Precedence === undefined)
              this.groups.push({
                text: group.name || group.id,
                value: group,
              });
          });

          // console.log("SUCESSO");

          this.loading = false;
          // return this.itemsRand;
        });

      // Cognito.listGroups()
      //   .then((res) => res.text())
      //   .then((res) => {
      //     console.log("GRUPOS:", res);
      //     this.groups = [];
      //     JSON.parse(res).Groups.forEach((group) => {
      //       console.log("group.Description:", group.Description);
      //       console.log("group.GroupName:", group.GroupName);
      //       if (group.Precedence === undefined)
      //         this.groups.push({
      //           text: group.Description || group.GroupName,
      //           value: group,
      //         });
      //     });
      //     this.loading = false;
      //   });
    },
    listAssignedGroups(user) {
      Cognito.listGroupsForUser(user.Username)
        .then((res) => res.text())
        .then((res) => {
          if (this.assignedGroups === null) {
            this.assignedGroups = {
              [user.Username]: JSON.parse(res).Groups,
            };
          } else {
            this.assignedGroups = {
              ...this.assignedGroups,
              [user.Username]: JSON.parse(res).Groups,
            };
          }
        });
    },
    counterById(user) {
      return Counter.getById(user.Username)
        .then((res) => res.text())
        .then((res) => {
          const response = JSON.parse(res);
          if (this.counter === null) {
            this.counter = {
              [user.Username]: {
                Count: response.count,
                LastAccess: response.log[0] ? response.log[0].date_access : "",
              },
            };
          } else {
            this.counter = {
              ...this.counter,
              [user.Username]: {
                Count: response.count,
                LastAccess: response.log[0] ? response.log[0].date_access : "",
              },
            };
          }
        });
    },

    getAllPagination(paginationToken, allUsersTemp = []) {
      // console.log("pad pag", paginationToken);
      this.loading = true;
      return Cognito.getAllPagination(paginationToken)
        .then((res) => res.json())
        .then((res) => {
          const users = [...allUsersTemp, ...res.users];
          const nextToken = res.next;

          this.loading = false;

          return nextToken ? this.getAllPagination(nextToken, users) : users;
        });
    },

    fetch() {
      this.loading = true;

      this.getAllPagination().then((users) => {
        this.items = users;
        this.items.forEach((item) => {
          const attribute = item.Attributes.filter(
            (attribute) => attribute.Name === "email"
          );
          item.Email = attribute[0].Value;
          // TODO: REFATORAR ESSA PARTE, FAZENDO REQUISIÇÃO POR LOTE AO INVÉS DE INDIVIDUAL
          // this.listAssignedGroups(item);
          this.counterById(item);
        });
        //

        this.loading = false;
        // console.log("itens pós counter", users);
      });

      // console.log(users);

      // Cognito.getAll()
      //   .then((res) => res.text())
      //   .then((res) => {
      //     this.items = JSON.parse(res);
      //     this.items.forEach((item) => {
      //       const attribute = item.Attributes.filter(
      //         (attribute) => attribute.Name === "email"
      //       );
      //       item.Email = attribute[0].Value;
      //       this.listAssignedGroups(item);
      //       this.counterById(item);
      //     });

      //     this.loading = false;
      //   });
    },
  },
  watch: {
    newUser: {
      handler(value) {
        // console.log("VALOR DO NEWUSER: ", value);
        // console.log("VALOR DO NEWUSER1: ", value.project.value.levels);
        if (value.god === true) {
          value.project = null;
          value.role = null;
        } else {
          this.botoes = value.project.value.levels;
        }
        // else{
        //           Projects.get(value)
        //   .then((res) => res.json())
        //   .then((res) => {
        //     console.log("PASSEI AQUI TBM");
        //     // console.log(res); userId
        //     this.originalStructure = res;
        //     console.log("ORIGINAL STRUCTURE: ", this.originalStructure);

        //     this.projectStructure = res.levels;
        //     console.log("PROJECT STRUCT: ", this.projectStructure)})
        // }
      },
      deep: true,
    },
    editUser: {
      handler(value) {
        // console.log("VALOR DO editUSER: ", value);
        // console.log("VALOR DO editUSER2: ", value.project.value.levels);
        if (value.god === true) {
          value.project = null;
          value.role = null;
        } else {
          this.botoesedicao = value.project.value.levels;
        }
        // else{
        //           Projects.get(value)
        //   .then((res) => res.json())
        //   .then((res) => {
        //     console.log("PASSEI AQUI TBM");
        //     // console.log(res); userId
        //     this.originalStructure = res;
        //     console.log("ORIGINAL STRUCTURE: ", this.originalStructure);

        //     this.projectStructure = res.levels;
        //     console.log("PROJECT STRUCT: ", this.projectStructure)})
        // }
      },
      deep: true,
    },
    async userAuthenticated(user) {
      if (user) {
        this.fetch();
        this.listGroups();

        const groups = await Cognito.listGroupsForUser(
          user.username,
          user.signInUserSession.idToken.jwtToken
        )
          .then((res) => res.json())
          .then((res) => res.Groups);

        let result = "";

        groups.forEach((group) => {
          if (result === "god") return;
          if (result === "admin") return;
          result = group.GroupName;
        });

        result = result === "" ? "viewer" : result;

        this.role = result;
      }
    },
  },
  created() {
    this.CURRENT_AUTHENTICATED_USER();
  },
  // mounted() {
  //   this.fetch();
  // },
  filters: {
    enabled: (enabled) => (enabled ? "Sim" : "Não"),
    simplifyUuid: (uuid) => uuid.split("-")[0],
    localeDate: (date) => (date ? new Date(date).toLocaleString() : ""),
    activateBtn: (enabled) => (enabled ? "Habilitar" : "Desabilitar"),
  },
};
</script>