import Auth from "./_auth"

const api = 'https://koriixo1z9.execute-api.us-east-1.amazonaws.com'
const stage = 'dev'

const url = (projectId) => `${api}/${stage}/project/${projectId || ''}`

export default {
    async getAll() {
        const token = (await Auth.token()).getIdToken().jwtToken

        const params = {
            method: 'GET',
            headers: {
                "Content-type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${token}`
            }
        }
        return fetch(url(), params)
    },
    async get(id) {
        const token = (await Auth.token()).getIdToken().jwtToken

        const params = {
            method: 'GET',
            headers: {
                "Content-type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${token}`
            }
        }
        return fetch(url(id), params)
    },
    async getByDomain(domain) {
        const params = {
            method: 'GET',
            headers: {
                "Content-type": "application/json",
                "Accept": "application/json",
            }
        }
        return fetch(`${url()}domain/${domain}`, params)
    },
    async create(project) {
        const token = (await Auth.token()).getIdToken().jwtToken

        const params = {
            method: 'POST',
            headers: {
                "Content-type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify(project)
        }
        return fetch(url(), params)
    },
    async update(project) {
        const token = (await Auth.token()).getIdToken().jwtToken

        const id = project.id
        const params = {
            method: 'PUT',
            headers: {
                "Content-type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify(project)
        }
        return fetch(url(id), params)
    },
    async delete(project) {
        const token = (await Auth.token()).getIdToken().jwtToken

        const id = project.id
        const params = {
            method: 'DELETE',
            headers: {
                "Content-type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${token}`
            }
        }
        return fetch(url(id), params)
    },
}