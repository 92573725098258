import Auth from "./_auth"

const api = 'https://72ym20kkud.execute-api.us-east-1.amazonaws.com'
const stage = 'dev'

const url = () => `${api}/${stage}`

export default {
    async get(user, project) {
        const token = (await Auth.token()).getIdToken().jwtToken

        const params = {
            method: 'GET',
            headers: {
                "Content-type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${token}`
            }
        }
        return fetch(`${url()}/user/${user}/project/${project}`, params)
    },
    async getAll() {
        const token = (await Auth.token()).getIdToken().jwtToken

        const params = {
            method: 'GET',
            headers: {
                "Content-type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${token}`
            }
        }
        return fetch(url(), params)
    },
    async getByUser(userId) {
        const token = (await Auth.token()).getIdToken().jwtToken

        const params = {
            method: 'GET',
            headers: {
                "Content-type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${token}`
            }
        }
        return fetch(`${url()}/user/${userId}`, params)
    },
    async create(userProject) {
        const token = (await Auth.token()).getIdToken().jwtToken

        const params = {
            method: 'POST',
            headers: {
                "Content-type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify(userProject)
        }
        return fetch(url(), params)
    },
    async delete(userProject) {
        const token = (await Auth.token()).getIdToken().jwtToken

        const params = {
            method: 'DELETE',
            headers: {
                "Content-type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify(userProject)
        }
        return fetch(`${url()}/user/${userProject.user_id}/project/${userProject.project_id}`, params)
    },
    async deleteProject(project) {
        const token = (await Auth.token()).getIdToken().jwtToken

        const params = {
            method: 'DELETE',
            headers: {
                "Content-type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${token}`
            }
        }
        return fetch(`${url()}/project/${project.id}`, params)
    },

    async updateUserProject(userId,projectId,userProject) {
        const token = (await Auth.token()).getIdToken().jwtToken

        const params = {
            method: 'PUT',
            headers: {
                "Content-type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify(userProject)
        }
        return fetch(`${url()}/user/${userId}/project/${projectId}`, params)
    }
}