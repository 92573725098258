<template>
  <v-container grid-list-md text-xs-center>
    <v-row>
      <v-col cols="6">
        <v-card flat class="mx-auto">
          <v-card-title>Arquivos para upload</v-card-title>
          <v-card-text>
            <v-file-input
              :disabled="loading"
              :loading="loading"
              v-model="files"
              multiple
              placeholder="Adicionar arquivos"
            >
              <template v-slot:selection="{ index, text }">
                <v-chip dark label small>{{ text }}</v-chip>
              </template>
            </v-file-input>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="upload()" :disabled="loading" :loading="loading" color="primary">
              Upload
              <v-icon right dark>mdi-cloud-upload</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card flat class="mx-auto">
          <v-card-title>Arquivos disponíveis</v-card-title>
          <v-list-item v-for="file in storedFiles" :key="file.Key" three-line>
            <v-list-item-content>
              <v-list-item-title>{{ file.Key | name }}</v-list-item-title>
              <v-list-item-subtitle>Modificado: {{ file.LastModified | localeDate }}</v-list-item-subtitle>
              <v-list-item-subtitle>Tamanho: {{ file.Size | bytesToSize }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <div>
                <!-- <v-btn :href="download(file)" target="_blank" icon color="primary"> -->
                <v-btn @click="handleDownload(file)" icon color="primary">
                  <v-icon>mdi-cloud-download</v-icon>
                </v-btn>
                <v-btn @click="deleteFile(file)" icon color="red">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
            </v-list-item-action>
          </v-list-item>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="fetch()" :disabled="loading" :loading="loading" color="primary">
              Atualizar
              <v-icon right dark>mdi-refresh</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import s3 from "@/repositories/bucket";
import uuid from "uuid/v4";

export default {
  data: () => ({
    files: [],
    settings: [],
    loading: false
  }),
  computed: {
    storedFiles() {
      return this.$store.state.files;
    },
    project() {
      return this.$route.params.id;
    },
    temporary() {
      return this.project ? null : `temp/${uuid()}`;
    },
    path() {
      return this.project || this.temporary;
    }
  },
  methods: {
    async upload() {
      this.loading = true;
      try {
        await Promise.all(
          this.files.map(file => {
            return s3.putObject(this.path, file);
          })
        );
        this.files = [];
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
      this.fetch();
    },
    async fetch() {
      this.loading = true;
      try {
        this.$store.commit(
          "setFiles",
          (await s3.listObjects(this.path)).Contents
        );
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    },
    download(file) {
      return s3.getSignedUrl(...file.Key.split("/"));
    },
    
    
    // Função criada para download assíncrono
    async handleDownload(file) {
      try {
        console.log("Passei por download em Files...");
        const signedUrl = await s3.getSignedUrl(...file.Key.split("/"));
        window.location.href = signedUrl; // Navegação manual para a URL assinada
      } catch (error) {
        console.error('Erro ao obter URL assinada:', error);
        // Trate o erro conforme necessário
      }
    },      
    
    deleteFile(file) {
      this.loading = true;
      try {
        const key = file.Key.split("/")[1];
        s3.deleteObjects(this.path, [key]);
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
      this.fetch();
    }
  },

    mounted() {
    this.fetch();
  },
  filters: {
    name(path) {
      const arr = path.split("/");
      return arr[arr.length - 1];
    },
    localeDate(date) {
      return date instanceof Date
        ? date.toLocaleString()
        : new Date(date).toLocaleString() || "";
    },
    bytesToSize(bytes) {
      let sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    }
  }
};
</script>

<style>
</style>